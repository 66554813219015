import React, { Suspense, lazy } from 'react';
import currency from 'currency.js';
import { isMobileOnly } from 'react-device-detect';
import { Drawer, Form, FormInstance, Row, Col, Button, Input, InputNumber, Select, Switch, Divider, Alert, Checkbox, Space, Typography, Skeleton } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { calcPaymentAmountFromDetails } from 'utils/paymentCalc';
import { isNumber } from 'utils/numberFormatting';
import { displayConfirmModal } from 'utils/modals';

import type { Currency } from 'models/currency';
import type { IInventory } from 'models/inventory';
import { type ITract, type ITractPricing, TractStatus } from 'models/tract';
import type { IClient } from 'models/client';
import type { IPostalAddress } from 'models/common/postalAddress';

import { ClientAutoComplete, IClientAutoCompleteValue } from 'components/clients/autocomplete';

import { createTract, updateTract, setTractOwner, removeTractOwner } from 'api/tracts';
import { displayErrorNotification } from 'utils/errors';

const MapPreviewButton = lazy(() => import('components/misc/mapPreviewBtn'));

interface IFormValues {
    label: string;
    number: number;
    acres: number;
    status: TractStatus;
    owner: IClientAutoCompleteValue;
    costTotal: Currency;
    costPerAcre: Currency;
    taxReimbursementEligibility: boolean;
    isFilledOut: boolean;
    isFinanced: boolean;
    pricePerAcre: Currency;
    downPayment: Currency;
    years: number;
    interestRate: string;
    salesPrice: Currency;
    salesPriceEditOnly: boolean;
    pricePerAcreEditOnly: boolean;
    parcelNumbers: string[];
    locationIsCoordinates: boolean;
    latitude?: number;
    longitude?: number;
    zoom?: number;
}

interface IEditTractDrawerProps {
    orgId: string;
    inventory: IInventory;
    tract?: ITract;
    owner?: IClient;
    isVisible: boolean;
    close: (saved: boolean) => void;
}

interface IEditTractDrawerState {
    isSaving: boolean;
    showPricingInputs: boolean;
    editTotal: boolean;
    calculated: {
        amountFinanced: Currency;
        paymentAmount: Currency;
        totalPayments: Currency;
    };
}

export class EditTractDrawer extends React.PureComponent<IEditTractDrawerProps, IEditTractDrawerState> {
    state: Readonly<IEditTractDrawerState> = {
        isSaving: false,
        showPricingInputs: false,
        editTotal: false,
        calculated: {
            amountFinanced: '0',
            paymentAmount: '0',
            totalPayments: '0',
        },
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    //#region closing and submitting logic
    onClose = () => {
        this.props.close(false);

        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }

        this.setState({
            showPricingInputs: false,
            isSaving: false,
            calculated: {
                amountFinanced: '0',
                paymentAmount: '0',
                totalPayments: '0',
            },
        });
    };

    handleSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!this.formRef.current) {
            return;
        }

        this.formRef.current.validateFields().then(async (values) => {
            let tract: Partial<ITract> = {
                inventoryId: this.props.inventory.id,
                label: values.label,
                number: values.number,
                acres: values.acres,
                status: values.status,
                costTotal: values.costTotal ? values.costTotal.replace(/[^0-9.]/g, '') : '0',
                costPerAcre: values.costPerAcre ? values.costPerAcre.replace(/[^0-9.]/g, '') : '0',
                taxReimbursementEligibility: values.taxReimbursementEligibility ? values.taxReimbursementEligibility : false,
                parcelNumbers: values.parcelNumbers ? values.parcelNumbers : [],
            };

            if (values.isFilledOut) {
                tract.paymentOption = {
                    isFilledOut: values.isFilledOut,
                    isFinanced: values.isFinanced,
                    pricePerAcre: values.pricePerAcre ? values.pricePerAcre.replace(/[^0-9.]/g, '') : '0',
                    downPayment: values.downPayment ? values.downPayment.replace(/[^0-9.]/g, '') : '0',
                    years: values.years,
                    interestRate: values.interestRate ? values.interestRate.replace(/[^0-9.]/g, '') : '0',
                    salesPrice: values.salesPrice ? values.salesPrice.replace(/[^0-9.]/g, '') : '0',
                    updatedAt: new Date(),
                } as ITractPricing;
            } else {
                tract.paymentOption = {
                    isFilledOut: false,
                } as ITractPricing;
            }

            if (values.latitude && values.longitude && values.zoom) {
                tract.address = {
                    location: {
                        latitude: values.latitude,
                        longitude: values.longitude,
                        zoomLevel: values.zoom,
                    },
                } as IPostalAddress;
            }

            const ownerInfo = values.owner;

            let newOwner = false;
            if (this.props.tract && this.props.tract.owner && ownerInfo.id && this.props.tract.owner.id !== ownerInfo.id) {
                console.log(this.props.tract.owner.id, ownerInfo.id);
                if (!(await displayConfirmModal('New Owner Detected', 'Are you sure you want to set a new owner?', 'Yes!', 'Cancel'))) {
                    return;
                }

                newOwner = true;
            }

            let ownerRemoved = false;
            if (this.props.tract && this.props.tract.owner && this.props.tract.owner.id && !ownerInfo.id) {
                ownerRemoved = true;
            }

            this.setState({ isSaving: true }, async () => {
                try {
                    let tractId: string = '';
                    if (this.props.tract) {
                        tractId = this.props.tract.id;
                        const toUpdate: Partial<ITract> = Object.assign({}, this.props.tract, tract);
                        await updateTract(this.props.orgId, this.props.inventory.id, toUpdate);
                    } else {
                        tract = await createTract(this.props.orgId, tract);
                        tractId = tract.id!;
                    }

                    if (ownerInfo.id && newOwner) {
                        await setTractOwner(this.props.orgId, this.props.inventory.id, tractId, { id: ownerInfo.id });
                    }

                    if (ownerRemoved) {
                        await removeTractOwner(this.props.orgId, this.props.inventory.id, tractId);
                    }

                    this.props.close(true);

                    if (this.formRef.current) {
                        this.formRef.current.resetFields();
                    }

                    this.setState({
                        showPricingInputs: false,
                        calculated: {
                            amountFinanced: '0',
                            paymentAmount: '0',
                            totalPayments: '0',
                        },
                    });
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        });
    }
    //#endregion closing and submitting logic

    //#region tract label
    get tractLabel() {
        return (
            <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Please input the tract\'s label.' }]}>
                <Input
                    disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                />
            </Form.Item>
        );
    }
    //#endregion

    get tractNumber() {
        return (
            <Form.Item name="number" label="Number" rules={[{ required: true, message: 'The tract number is required.' }]}>
                <InputNumber
                    min={1}
                    step={1}
                    disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                />
            </Form.Item>
        );
    }

    //#region tract acres
    get tractAcres() {
        return (
            <Form.Item name="acres" label="Acres" rules={[{ required: true, message: 'Please input the amount of acres for the tract.' }]}>
                <InputNumber
                    min={0}
                    step={0.01}
                    disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                    onChange={this.onCostOrAcreChange}
                />
            </Form.Item>
        );
    }
    //#endregion

    //#region tract status
    get tractStatus() {
        return (
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please state the status of this tract.' }]}>
                <Select<TractStatus> placeholder="Status" disabled={this.state.isSaving || (this.props.tract && !!this.props.tract.loan.id)}>
                    <Select.Option value={TractStatus.Available}>Available</Select.Option>
                    <Select.Option value={TractStatus.UnderContract}>Under Contract</Select.Option>
                    <Select.Option value={TractStatus.Sold}>Sold</Select.Option>
                    <Select.Option value={TractStatus.PaidOff}>Paid Off</Select.Option>
                    <Select.Option value={TractStatus.InDefault}>In Default</Select.Option>
                    <Select.Option value={TractStatus.Pending}>Pending</Select.Option>
                    <Select.Option value={TractStatus.ResendContract}>Resend Contract</Select.Option>
                    <Select.Option value={TractStatus.Late}>Late</Select.Option>
                </Select>
            </Form.Item>
        );
    }
    //#endregion

    //#region owner selection
    get tractOwnerInput() {
        return (
            <Form.Item name="owner" label="Owner">
                <ClientAutoComplete
                    orgId={this.props.inventory.organization.id}
                    disabled={this.state.isSaving || (!!this.props.tract && !!this.props.tract.loan.id)} />
            </Form.Item>
        );
    }
    //#endregion owner selection

    //#region cost section
    onCostOrAcreChange = () => {
        if (!this.formRef.current) {
            return;
        }

        const acres = this.formRef.current.getFieldValue('acres');
        if (!acres) {
            return;
        }

        if (!this.formRef.current.isFieldTouched('costTotal') && this.props.inventory.landDetails && this.props.inventory.landDetails.costPerAcre) {
            const totalCost = currency(acres, { precision: 6 }).multiply(this.props.inventory.landDetails.costPerAcre);

            this.formRef.current.setFieldsValue({
                costPerAcre: this.props.inventory.landDetails.costPerAcre,
                costTotal: currency(totalCost, { precision: 2 }).toString(),
            });

            return;
        }

        if (this.state.editTotal) {
            const totalCost = this.formRef.current.getFieldValue('costTotal');
            if (!totalCost) {
                return;
            }

            const perAcreCost = currency(totalCost, { precision: 2 }).divide(acres);

            this.formRef.current.setFieldsValue({ costPerAcre: perAcreCost.toString() });

            return;
        }

        const perAcreCost = this.formRef.current.getFieldValue('costPerAcre');
        if (!perAcreCost) {
            return;
        }

        const totalCost = currency(perAcreCost, { precision: 2 }).multiply(acres);

        this.formRef.current.setFieldsValue({ costTotal: totalCost.toString() });
    };

    get totalCostToggle() {
        return (
            <Checkbox
                checked={this.state.editTotal}
                disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                onChange={(e) => this.setState({ editTotal: e.target.checked })}
            />
        );
    }

    get totalCostInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.acres !== curr.acres}>
                {({ getFieldValue }) => {
                    let disabled = (this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving || !getFieldValue('acres');
                    if (!disabled && !this.state.editTotal) {
                        disabled = true;
                    }

                    return (
                        <Form.Item name="costTotal" label="Total Cost of Development" rules={[{ required: this.state.editTotal, message: 'Please provide the total cost of development, you can update this later.' }]}>
                            <Input
                                prefix="$"
                                addonBefore={this.totalCostToggle}
                                onChange={this.onCostOrAcreChange}
                                disabled={disabled}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get perAcreCostToggle() {
        return (
            <Checkbox
                checked={!this.state.editTotal}
                disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                onChange={(e) => this.setState({ editTotal: !e.target.checked })}
            />
        );
    }

    get costPerAcreInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.acres !== curr.acres}>
                {({ getFieldValue }) => {
                    let disabled = (this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving || !getFieldValue('acres');
                    if (!disabled && this.state.editTotal) {
                        disabled = true;
                    }

                    return (
                        <Form.Item name="costPerAcre" label="Per Acre Development Cost" rules={[{ required: !this.state.editTotal, message: 'Please provide the per acre cost of development, you can update this later.' }]}>
                            <Input
                                prefix="$"
                                addonBefore={this.perAcreCostToggle}
                                onChange={this.onCostOrAcreChange}
                                disabled={disabled}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion cost section

    //#region tax reimbursement
    get taxReimbursementEligibilityInput() {
        return (
            <Form.Item name="taxReimbursementEligibility" label="Eligible for Property Tax Reimbursement" valuePropName="checked">
                <Switch
                    checkedChildren="Is Eligible"
                    unCheckedChildren="Not Eligible"
                    disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving} //TODO: determine how we can update this if there is a loan against the tract
                />
            </Form.Item>
        );
    }
    //#endregion tax reimbursement

    get parcelNumberInput() {
        return (
            <Form.Item name="parcelNumbers" label="Parcel Numbers">
                <Select
                    placeholder="Enter parcel number"
                    mode="tags"
                    style={{ width: '100%' }}
                    disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                />
            </Form.Item>
        );
    }

    //#region pricing
    //#region pricing switch
    private onPricingChange = (changedValues: Partial<IFormValues>) => {
        if (!this.formRef.current) {
            return;
        }

        if (changedValues.salesPrice) {
            this.salesPriceChanged();
        } else if (changedValues.pricePerAcre) {
            this.pricePerAcreChanged();
        } else if (changedValues.acres) {
            this.acresChanged();
        }

        const { getFieldValue } = this.formRef.current;

        const interestRate = currency(getFieldValue('interestRate') as Currency, { precision: 10 });
        const years = currency(getFieldValue('years') as number, { precision: 0 });
        const downPayment = currency(getFieldValue('downPayment') as Currency, { precision: 5 });
        const pricePerAcre = currency(getFieldValue('pricePerAcre') as Currency, { precision: 5 });
        const acres = currency(getFieldValue('acres') as string, { precision: 5 });

        const items = [years, pricePerAcre, acres];
        if (!items.every((v) => isNumber(v.intValue) && v.intValue > 0)) {
            return;
        }

        const salesPrice = currency(getFieldValue('salesPrice'));
        const amountFinanced = currency(salesPrice).subtract(downPayment);
        const paymentAmount = currency(calcPaymentAmountFromDetails(interestRate, years, acres, pricePerAcre, downPayment), { precision: 2 });
        const totalPayments = currency(paymentAmount).multiply(currency(years).multiply(12));

        if (amountFinanced.format() === this.state.calculated.amountFinanced && paymentAmount.format() === this.state.calculated.paymentAmount && totalPayments.format() === this.state.calculated.totalPayments) {
            return;
        }

        this.setState({
            calculated: {
                amountFinanced: amountFinanced.format(),
                paymentAmount: paymentAmount.format(),
                totalPayments: totalPayments.format(),
            },
        });
    }

    acresChanged = () => {
        if (!this.formRef.current) {
            return;
        }

        const { getFieldValue } = this.formRef.current;

        //if we are only allowed to edit the sales price,
        //then we need to adjust the price per acre
        if (getFieldValue('salesPriceEditOnly')) {
            this.salesPriceChanged();
        } else {
            //otherwise, we are only allowed to edit the price per acre
            //thus we need to adjust the sales price
            this.pricePerAcreChanged();
        }
    }

    salesPriceChanged = () => {
        if (!this.formRef.current) {
            return;
        }

        const { getFieldValue, setFieldsValue } = this.formRef.current;

        const salesPrice = getFieldValue('salesPrice');
        const acres = currency(getFieldValue('acres'), { precision: 5 });
        if (acres.intValue <= 0) {
            return;
        }

        setFieldsValue({ pricePerAcre: currency(salesPrice).divide(acres).toString() });
    }

    pricePerAcreChanged = () => {
        if (!this.formRef.current) {
            return;
        }

        const { getFieldValue, setFieldsValue } = this.formRef.current;

        const pricePerAcre = getFieldValue('pricePerAcre');
        const acres = currency(getFieldValue('acres'), { precision: 5 });
        if (acres.intValue <= 0) {
            return;
        }

        setFieldsValue({ salesPrice: currency(pricePerAcre).multiply(acres).toString() });
    }

    get pricingInputSwitch() {
        return (
            <Divider orientation="left">
                <Form.Item name="isFilledOut" valuePropName="checked" noStyle>
                    <Switch
                        checkedChildren="Add Pricing"
                        unCheckedChildren="No Pricing"
                        disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                    />
                </Form.Item>

                { this.financedOrCashSaleSwitch }
            </Divider>
        );
    }

    get financedOrCashSaleSwitch() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('isFilledOut')) {
                        return null;
                    }

                    return (
                        <Form.Item name="isFinanced" noStyle valuePropName="checked">
                            <Switch
                                checkedChildren="Financed"
                                unCheckedChildren="Cash Price"
                                disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                                style={{ marginLeft: '15px' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion pricing switch

    //#region price per acre
    onPricePerAcreEditChecked = (e: CheckboxChangeEvent) => {
        if (!this.formRef.current || !e || !e.target) {
            return;
        }

        this.formRef.current.setFieldsValue({ salesPriceEditOnly: !e.target.checked });
    }

    get pricePerAcreEdit() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {() => {
                    return (
                        <Form.Item name="pricePerAcreEditOnly" noStyle valuePropName="checked">
                            <Checkbox onChange={this.onPricePerAcreEditChecked} disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get pricePerAcre() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.pricePerAcreEditOnly !== curr.pricePerAcreEditOnly || prev.salesPriceEditOnly !== curr.salesPriceEditOnly}>
                {({ getFieldValue }) => {
                    let disabled = (this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving;
                    if (!getFieldValue('pricePerAcreEditOnly')) {
                        disabled = true;
                    }

                    return (
                        <Form.Item name="pricePerAcre" label="Price per Acre" preserve rules={[{ required: getFieldValue('isFilledOut'), message: 'Please input the amount of acres for the tract.' }]}>
                            <Input addonBefore={this.pricePerAcreEdit} prefix="$" style={{width: '75%'}} disabled={disabled} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion price per acre

    //#region down payment amount
    get downPaymentAmount() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.isFinanced !== curr.isFinanced}>
                {({ getFieldValue }) => {
                    const isRequired = getFieldValue('isFilledOut') && getFieldValue('isFinanced');

                    return (
                        <Form.Item name="downPayment" label="Down Payment" preserve rules={[{ required: isRequired, message: 'Please input the down payment amount for the tract.' }]}>
                            <Input prefix="$" style={{width: '75%'}} disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion down payment amount

    //#region years
    get years() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.isFinanced !== curr.isFinanced}>
                {({ getFieldValue }) => {
                    const isRequired = getFieldValue('isFilledOut') && getFieldValue('isFinanced');

                    return (
                        <Form.Item name="years" label="Years" preserve rules={[{ required: isRequired, message: 'Please input the amount of years for the financing for the tract.' }]}>
                            <InputNumber
                                min={1}
                                step={1}
                                style={{width: '75%'}}
                                disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion

    //#region interest rate
    get interestRate() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.isFinanced !== curr.isFinanced}>
                {({ getFieldValue }) => {
                    const isRequired = getFieldValue('isFilledOut') && getFieldValue('isFinanced');

                    return (
                        <Form.Item name="interestRate" label="Interest Rate" preserve rules={[{ required: isRequired, message: 'Please input the interest rate for the tract.' }]}>
                            <Input suffix="%" style={{width: '75%'}} disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion

    //#region sales price
    onSalesPriceEditChecked = (e: CheckboxChangeEvent) => {
        if (!this.formRef.current || !e || !e.target) {
            return;
        }

        this.formRef.current.setFieldsValue({ pricePerAcreEditOnly: !e.target.checked });
    }

    get salesPriceEdit() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {() => {
                    return (
                        <Form.Item name="salesPriceEditOnly" noStyle valuePropName="checked">
                            <Checkbox onChange={this.onSalesPriceEditChecked} disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get salesPrice() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.isFinanced !== curr.isFinanced  || prev.pricePerAcreEditOnly !== curr.pricePerAcreEditOnly || prev.salesPriceEditOnly !== curr.salesPriceEditOnly}>
                {({ getFieldValue }) => {
                    const isRequired = getFieldValue('isFilledOut');
                    let disabled = (this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving;
                    if (!getFieldValue('salesPriceEditOnly')) {
                        disabled = true;
                    }

                    return (
                        <Form.Item name="salesPrice" label="Sales Price" preserve rules={[{ required: isRequired, message: 'Please input the sales price for the tract.' }]}>
                            <Input addonBefore={this.salesPriceEdit} prefix="$" style={{width: '75%'}} disabled={disabled} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion

    //#region calculated amount financed
    get amountFianced() {
        return (
            <Form.Item label="Amount Financed">
                <Input
                    value={this.state.calculated.amountFinanced}
                    prefix="$"
                    disabled
                    style={{width: '75%'}}
                />
            </Form.Item>
        );
    }
    //#endregion

    //#region calculated monthly payment
    get monthlyPayment() {
        return (
            <Form.Item label="Monthly Payment">
                <Input
                    value={this.state.calculated.paymentAmount}
                    prefix="$"
                    disabled
                    style={{width: '75%'}}
                />
            </Form.Item>
        );
    }
    //#endregion

    //#region calculated total payments
    get totalPayments() {
        return (
            <Form.Item label="Total of Payments">
                <Input
                    value={this.state.calculated.totalPayments}
                    prefix="$"
                    disabled
                    style={{width: '75%'}}
                />
            </Form.Item>
        );
    }
    //#endregion
    //#endregion pricing

    get loanWarning() {
        if (!this.props.tract || !this.props.tract.loan.id) {
            return null;
        }

        return (
            <Alert
                message="Active Loan"
                description="As this tract has an active loan, the details can not be changed."
                type="warning"
                showIcon
                style={{ marginBottom: '15px' }}
            />
        );
    }

    get pricingInputs() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.isFilledOut !== curr.isFilledOut || prev.isFinanced !== curr.isFinanced}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('isFilledOut')) {
                        return null;
                    }

                    return (
                        <Row gutter={16}>
                            <Col span={12}>{this.salesPrice}</Col>
                            <Col span={12}>{this.pricePerAcre}</Col>
                            { getFieldValue('isFinanced') ?
                                <React.Fragment>
                                    <Col span={12}>{this.downPaymentAmount}</Col>
                                    <Col span={12}>{this.years}</Col>
                                    <Col span={12}>{this.interestRate}</Col>
                                    <Col span={12}>{this.amountFianced}</Col>
                                    <Col span={12}>{this.monthlyPayment}</Col>
                                    <Col span={12}>{this.totalPayments}</Col>
                                </React.Fragment>
                            : null}
                        </Row>
                    );
                }}
            </Form.Item>
        );
    }

    get locationDivider() {
        return (
            <Divider orientation="left">
                Location:&nbsp;
                <Form.Item name="locationIsCoordinates" valuePropName="checked" noStyle>
                    <Switch
                        checkedChildren="Coordinates"
                        unCheckedChildren="Address"
                        disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                    />
                </Form.Item>
            </Divider>
        );
    }

    get coordinateInputs() {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="latitude" label="Latitude">
                        <InputNumber
                            suffix="°"
                            disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                            style={{ width: '100%' }}
                            step={0.0000001}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="longitude" label="Longitude">
                        <InputNumber
                            suffix="°"
                            disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                            style={{ width: '100%' }}
                            step={0.0000001}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="zoom" label="Zoom Level">
                        <InputNumber
                            disabled={(this.props.tract && !!this.props.tract.loan.id) || this.state.isSaving}
                            style={{ width: '100%' }}
                            min={1}
                            max={22}
                            step={0.25}
                        />
                    </Form.Item>
                </Col>

                <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.latitude !== curr.latitude || prev.longitude !== curr.longitude || prev.zoom !== curr.zoom}>
                    {({ getFieldValue }) => {
                        const lat = getFieldValue('latitude');
                        const lon = getFieldValue('longitude');
                        const zoom = getFieldValue('zoom');

                        if (!lat || !lon || !zoom) {
                            return null;
                        }

                        if (typeof lat !== 'number' || typeof lon !== 'number' || typeof zoom !== 'number') {
                            return null;
                        }

                        return (
                            <Col span={12}>
                                <Form.Item label="Preview on a Map">
                                    <Suspense fallback={<Skeleton.Button />}>
                                        <MapPreviewButton
                                            latitude={lat}
                                            longitude={lon}
                                            zoom={zoom}
                                        />
                                    </Suspense>
                                </Form.Item>
                            </Col>
                        );
                    }}
                </Form.Item>
            </Row>
        );
    }

    get locationInputs() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.locationIsCoordinates !== curr.locationIsCoordinates}>
                {({ getFieldValue }) => {
                    if (getFieldValue('locationIsCoordinates')) {
                        return this.coordinateInputs;
                    }

                    return (
                        <Alert
                            message="Address Input"
                            description="This feature is not yet implemented."
                            type="info"
                            showIcon
                        />
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        const { isSaving } = this.state;

        let title: string;
        if (this.props.tract) {
            title = `Edit: ${ this.props.tract.label } of ${ this.props.inventory.name }`;
        } else {
            title = `New Tract for: ${ this.props.inventory.name }`;
        }

        let width: string = '500px';
        if (isMobileOnly) {
            width = '100vw';
        }

        return (
            <Drawer
                title={title}
                width={width}
                onClose={this.onClose}
                open={this.props.isVisible}
                closable={!isSaving}
                maskClosable={!isSaving}
                destroyOnClose
                extra={
                    <Space>
                        <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={isSaving}>Cancel</Button>
                        <Button onClick={this.handleSubmit} type="primary" disabled={isSaving || (!!this.props.tract && !!this.props.tract.loan.id)} loading={isSaving}>Save</Button>
                    </Space>
                }
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    layout="vertical"
                    colon={false}
                    onValuesChange={this.onPricingChange}
                    disabled={isSaving}
                    initialValues={{
                        number: this.props.tract ? this.props.tract.number : 1,
                        label: this.props.tract ? this.props.tract.label : '',
                        acres: this.props.tract ? this.props.tract.acres : '',
                        status: this.props.tract ? this.props.tract.status : '',
                        owner: this.props.owner ? { id: this.props.owner.id, displayName: this.props.owner.displayName } : { id: '', displayName: '' },
                        isFilledOut: this.props.tract ? this.props.tract.paymentOption.isFilledOut : false,
                        isFinanced: this.props.tract ? this.props.tract.paymentOption.isFinanced : true,
                        pricePerAcre: this.props.tract ? this.props.tract.paymentOption.pricePerAcre : '3000',
                        pricePerAcreEditOnly: false,
                        downPayment: this.props.tract ? this.props.tract.paymentOption.downPayment : '2000',
                        years: this.props.tract ? this.props.tract.paymentOption.years : 30,
                        interestRate: this.props.tract ? this.props.tract.paymentOption.interestRate : '10.0',
                        salesPrice: this.props.tract ? this.props.tract.paymentOption.salesPrice : '10000',
                        salesPriceEditOnly: true,
                        costTotal: this.props.tract && this.props.tract.costTotal ? this.props.tract.costTotal : '0',
                        costPerAcre: this.props.tract && this.props.tract.costPerAcre ? this.props.tract.costPerAcre : '',
                        taxReimbursementEligibility: this.props.tract && typeof this.props.tract.taxReimbursementEligibility === 'boolean' ? this.props.tract.taxReimbursementEligibility : true,
                        parcelNumbers: this.props.tract ? this.props.tract.parcelNumbers : [],
                        locationIsCoordinates: true,
                        latitude: this.props?.tract?.address?.location ? this.props.tract.address.location.latitude : undefined,
                        longitude: this.props?.tract?.address?.location ? this.props.tract.address.location.longitude : undefined,
                        zoom: this.props?.tract?.address?.location ? this.props.tract.address.location.zoomLevel : undefined,
                    }}
                >
                    {this.loanWarning}

                    <Row gutter={16}>
                        <Col span={12}>{this.tractLabel}</Col>
                        <Col span={6}>{this.tractNumber}</Col>
                        <Col span={6}>{this.tractAcres}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.tractStatus}</Col>
                        <Col span={12}>{this.tractOwnerInput}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{ this.taxReimbursementEligibilityInput }</Col>
                        <Col span={12}>{ this.parcelNumberInput }</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{ this.totalCostInput }</Col>
                        <Col span={12}>{ this.costPerAcreInput }</Col>
                        <Col><Typography.Text>Use <Typography.Text code>0.00</Typography.Text> to pull from the inventory's cost per acre.</Typography.Text></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>{this.pricingInputSwitch}</Col>
                    </Row>
                    { this.pricingInputs }

                    <Row gutter={16}>
                        <Col span={24}>{this.locationDivider}</Col>
                    </Row>
                    { this.locationInputs }
                </Form>
            </Drawer>
        );
    }
}
