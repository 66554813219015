import React from 'react';
import { Modal, Row, Col, Form, FormInstance, Switch, Select } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { CommunicationPreference } from 'models';
import type { ILoan } from 'models/loan';
import { ILoanCommunicationPreferenceChangePayload } from 'models/payloads/loans';

import { updateCommunicationPreference } from 'api/loans';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

interface IFormValues {
    automatedCommunication: boolean;
    communicationPreferences: CommunicationPreference[];
}

interface IChangeCommunicationPreferencesModalProps {
    loan: Partial<ILoan>;
    isVisible: boolean;
    close: (updated?: boolean) => Promise<void>;
}

interface IChangeCommunicationPreferencesModalState {
    isSaving: boolean;
}

export class ChangeCommunicationPreferencesModal extends React.PureComponent<IChangeCommunicationPreferencesModalProps, IChangeCommunicationPreferencesModalState> {
    state: Readonly<IChangeCommunicationPreferencesModalState> = {
        isSaving: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    onClose = () => {
        this.props.close(false);
        this.formRef.current?.resetFields();
    }

    save = async () => {
        try {
            const values = await this.formRef.current!.validateFields();

            const payload: ILoanCommunicationPreferenceChangePayload = {
                automated: values.automatedCommunication,
                preferences: values.communicationPreferences,
            };

            this.setState({ isSaving: true }, async () => {
                const { loan } = this.props;

                try {
                    await updateCommunicationPreference(loan.organization!.id, loan.id!, payload);
                    trackUmami('Change Loan Communication Preference');

                    await this.props.close(true);
                    this.formRef.current?.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                }

                this.setState({ isSaving: false });
            });
        } catch (e) {
            console.warn('failed to validate the loan change communication preferences form:', e);
        }
    }

    get automatedCommunication() {
        return (
            <Form.Item
                name="automatedCommunication"
                label="Automated Communication"
                extra="Should the communication to the client be automated?"
                valuePropName="checked"
                rules={[{ required: true, message: 'Do you want automated communications enabled or not?' }]}
            >
                <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
            </Form.Item>
        );
    }

    get communicationPreferences() {
        return (
            <Form.Item
                name="communicationPreferences"
                label="Communication Preferences"
                extra="How should automated reminders and message be sent to the Client?"
                rules={[{ required: true, message: 'Please select at least one communication preference.' }]}
            >
                <Select<CommunicationPreference> style={{ width: '100%' }} mode="multiple">
                    <Select.Option key={CommunicationPreference.Email} value={CommunicationPreference.Email}>Email</Select.Option>
                    <Select.Option key={CommunicationPreference.SMS} value={CommunicationPreference.SMS}>SMS (text messages)</Select.Option>
                    <Select.Option key={CommunicationPreference.Mail} value={CommunicationPreference.Mail} disabled>Snail Mail</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    render() {
        return (
            <Modal
                open={this.props.isVisible}
                title={<span><PhoneOutlined /> Change Communication Preferences</span>}
                okText="Save"
                onOk={this.save}
                onCancel={this.onClose}
                closable={!this.state.isSaving}
                okButtonProps={{ disabled: this.state.isSaving, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    onFinish={this.save}
                    initialValues={{
                        automatedCommunication: this.props.loan && this.props.loan.communication && this.props.loan.communication.automated,
                        communicationPreferences: this.props.loan && this.props.loan.communication && this.props.loan.communication.preferences,
                    }}
                >
                    <Row>
                        <Col>
                            {this.automatedCommunication}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {this.communicationPreferences}
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
