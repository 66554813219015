import React, { useMemo, useState } from 'react';
import currency from 'currency.js';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Card, Steps } from 'antd';
import type { BreadcrumbProps, StepProps } from 'antd';

import { AccessControlledWrapper } from 'components/permissions';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';

import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { displayErrorNotification } from 'utils/errors';
import { sanitizeCurrency } from 'utils/numberFormatting';
import { trackUmami } from 'utils/umami';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { CommunicationPreference, OnlinePaymentPlatformFeePayee, LengthUnit, PaymentFrequency } from 'models';
import { ILoan, LoanStatus, LoanTransactionExtraApplication, LoanType } from 'models/loan';
import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { IClient } from 'models/client';
import { EscrowApplicationStep } from 'models/escrow';
import type { IInventoryAutoCompleteValue } from 'components/inventories/autocomplete';

import { getUser } from 'store/selectors/auth';
import { getSelectedOrg } from 'store/selectors/org';

import { createLoan } from 'api/loans';

import './newLoan.css';

import { INewLoanFormValues } from './formValues';

import { NewLoanStepOne } from './firstStep';
import { NewLoanTermsStep } from './termsStep';
import { NewLoanHowMuchStep } from './howMuchStep';
import { NewLoanStepFour } from './fourthStep';
import { NewLoanFormEscrowDetailsStep } from 'components/loans/escrow/escrowDetailsStep';
import { NewLoanReviewStep } from './reviewStep';

const NewLoanBase: React.FC = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const authUser = useSelector(getUser);
    const history = useHistory();
    const { orgId } = useParams<IOrgIdPathParams>();
    const [form] = Form.useForm<INewLoanFormValues>();

    const [currentStep, setCurrentStep] = useState(0);
    const [residentialInventory, setResidentialInventory] = useState<IInventory | undefined>();
    const [landInventory, setLandInventory] = useState<IInventory | undefined>();
    const [tracts, setTracts] = useState<ITract[]>([]);
    const [client, setClient] = useState<IClient | undefined>();
    const [isSaving, setSaving] = useState(false);

    //#region header
    const breadcrumbProps = useMemo((): BreadcrumbProps => {
        if (!authUser || !selectedOrg) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ selectedOrg.shortId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ selectedOrg.shortId }/loans`,
                    breadcrumbName: `${ selectedOrg.name }'s Loans`,
                },
                {
                    path: `/${ selectedOrg.shortId }/loan/new`,
                    breadcrumbName: 'New Loan',
                },
            ],
        };
    }, [authUser, selectedOrg]);

    const stepsContent = useMemo(() => {
        const steps: StepProps[] = [
            { title: 'What & Who', disabled: isSaving, },
            { title: 'Terms', disabled: isSaving, },
            { title: 'How Much', disabled: isSaving, },
            { title: 'Communication', disabled: isSaving, },
            { title: 'Escrow', disabled: isSaving, },
            { title: 'Review', disabled: isSaving, icon: isSaving ? <LoadingOutlined /> : undefined },
        ];

        return (
            <Steps
                type="navigation"
                size="small"
                current={currentStep}
                items={steps}
            />
        );
    }, [currentStep, isSaving]);

    const labelVal = form.getFieldValue('label');
    const headerProps: PageContainerProps = {
        title: `New Loan${ labelVal ? `: ${ labelVal }` : '' }`,
        breadcrumb: breadcrumbProps,
        onBack: () => history.push(`/${ orgId }/loans`),
        content: stepsContent,
        className: 'new-loan-header',
    };
    //#endregion

    //#region form related items
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            lg: { span: 5 },
            xl: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            lg: { span: 12, offset: 1 },
            xl: { span: currentStep === 2 ? 8 : 6, offset: 0 },
        },
    };

    const goNext = () => {
        let nextStep = currentStep + 1;

        const saveStep = 6;
        if (nextStep >= saveStep) {
            saveLoan();
            return;
        }

        setCurrentStep(nextStep);
    };

    const goBack = () => {
        let previousStep = currentStep - 1;
        if (previousStep < 0) {
            previousStep = currentStep;
        }

        setCurrentStep(previousStep);
    };

    const onRelatedSelected = (selectedTracts?: ITract[], client?: IClient, resInv?: IInventory, landInv?: IInventory) => {
        setTracts(selectedTracts || []);
        setClient(client);
        setResidentialInventory(resInv);
        setLandInventory(landInv);

        if (selectedTracts && selectedTracts.length > 0) {
            let salesPrice = currency(0, { precision: 2 });
            let downPayment = currency(0, { precision: 2 });

            selectedTracts.forEach((t) => {
                if (!t.paymentOption.isFilledOut) {
                    return;
                }

                salesPrice = salesPrice.add(t.paymentOption.salesPrice);
                downPayment = downPayment.add(t.paymentOption.downPayment);
            });

            form.setFieldsValue({
                salesPrice: salesPrice.intValue > 0 ? salesPrice.toString() : '',
                downPayment: downPayment.intValue > 0 ? downPayment.toString() : '',
                length: selectedTracts[0].paymentOption.isFilledOut ? selectedTracts[0].paymentOption.years : undefined,
                interestRate: selectedTracts[0].paymentOption.isFilledOut ? selectedTracts[0].paymentOption.interestRate : undefined,
                isExistingLoan: false,
            });
        }

        if (resInv) {
            form.setFieldsValue({
                salesPrice: resInv.residentialDetails?.price || '',
                isExistingLoan: false,
            });
        }
    };
    //#endregion form related items

    const saveLoan = async () => {
        try {
            setSaving(true);

            const client = form.getFieldValue('client');

            const loan: Partial<ILoan> = {
                label: form.getFieldValue('label'),
                type: form.getFieldValue('type'),
                client: { id: client.id, label: client.displayName },
                status: form.getFieldValue('status'),

                communication: {
                    automated: form.getFieldValue('automatedCommunication'),
                    preferences: form.getFieldValue('communicationPreferences'),
                },

                terms: {
                    downPayment: sanitizeCurrency(form.getFieldValue('downPayment')) || '0',
                    salesPrice: sanitizeCurrency(form.getFieldValue('salesPrice')) || '0',
                    adjustments: '0',
                    rate: sanitizeCurrency(form.getFieldValue('interestRate')) || '0',
                    interestSchedule: form.getFieldValue('interestSchedule'),
                    interestFormula: form.getFieldValue('interestFormula'),
                    firstPaymentBasis: form.getFieldValue('firstPaymentBasis'),
                    length: form.getFieldValue('length'),
                    lengthUnit: form.getFieldValue('lengthUnit'),
                    paymentFrequency: PaymentFrequency.Monthly,
                    payment: sanitizeCurrency(form.getFieldValue('paymentAmount')) || '0',
                    overwritePayment: form.getFieldValue('overwritePaymentAmount'),
                    extraApplication: form.getFieldValue('extraApplication'),
                    wasExisting: typeof form.getFieldValue('isExistingLoan') === 'undefined' ? false : form.getFieldValue('isExistingLoan'),
                    existing: {
                        principalPaid: sanitizeCurrency(form.getFieldValue('principalPaid')) || '0',
                        interestPaid: sanitizeCurrency(form.getFieldValue('interestPaid')) || '0',
                        interestPaidYtd: sanitizeCurrency(form.getFieldValue('interestPaidYtd')) || '0',
                        unpaidInterest: sanitizeCurrency(form.getFieldValue('interestBalanceUnpaid')) || '0',
                        lastPaidInFullPayment: form.getFieldValue('lastPaidInFullPayment') || 0,
                        lastPaymentDate: typeof form.getFieldValue('lastPaymentDate') === 'undefined' ? undefined : form.getFieldValue('lastPaymentDate').toJSON(),
                        nextPaymentDate: typeof form.getFieldValue('nextPaymentDate') === 'undefined' ? undefined : form.getFieldValue('nextPaymentDate').toJSON(),
                    },
                },

                defaulting: {
                    automatic: form.getFieldValue('defaultingAutomaticallyEnabled'),
                    days: form.getFieldValue('daysUntilInDefault'),
                    defaultsAfter: form.getFieldValue('daysUntilDefaulted') || undefined,
                },

                closingDate: form.getFieldValue('closingDate').toJSON(),
                downPaymentDate: form.getFieldValue('downPaymentDate')?.toJSON() || undefined,
                firstPaymentDate: form.getFieldValue('firstPaymentDate').toJSON(),

                lateFeeConfig: {
                    disabled: form.getFieldValue('lateFeeDisabled') || false,
                    tiers: form.getFieldValue('lateFeeTiers') || [],
                },

                onlinePaymentConfig: {
                    enabled: form.getFieldValue('onlinePaymentsEnabled') || false,
                    statementDescriptor: form.getFieldValue('onlineStatementDescriptor') || '',
                    platformFeePayee: form.getFieldValue('onlinePaymentPlatformFeePayee') || OnlinePaymentPlatformFeePayee.Buyer,
                    allowPrincipalOnly: form.getFieldValue('allowPrincipalOnly') || false,
                    allowAutoDraft: form.getFieldValue('allowAutoDraft') || false,
                },

                downPayment: form.getFieldValue('downPaymentConfig') || undefined,
            };

            if (form.getFieldValue('collectEscrow')) {
                loan.escrow = {
                    balance: sanitizeCurrency(form.getFieldValue('escrowStartingBalance')) || '0',
                    paymentAmount: sanitizeCurrency(form.getFieldValue('escrowPaymentAmount')) || '0',
                    applicationStep: form.getFieldValue('escrowApplicationStep') || EscrowApplicationStep.BeforeInterest,
                };
            }

            switch (loan.type) {
                case LoanType.Tract:
                    if (!landInventory) {
                        throw new Error('no land inventory is selected; please contact support with how you got here!');
                    }

                    const tractSelectionValue = form.getFieldValue('tract') as string[];
                    if (!Array.isArray(tractSelectionValue)) {
                        //TODO: HOW DO WE HANDLE THIS ERROR?!
                        throw new Error('invalid tract selection; please inform us how you got here!');
                    }

                    loan.tracts = tractSelectionValue.map((tractId) => ({ inventoryId: landInventory.id, tractId }));
                    break;
                case LoanType.Residential:
                    if (!residentialInventory) {
                        throw new Error('residential inventory data is missing, can not create a loan');
                    }

                    const resInvAutoVal = form.getFieldValue('residential') as IInventoryAutoCompleteValue;
                    if (resInvAutoVal.id !== residentialInventory.id) {
                        throw new Error('residential inventory data is mismatched');
                    }

                    loan.residential = {
                        inventoryId: residentialInventory.id,
                    };

                    loan.terms!.adjustments = sanitizeCurrency(form.getFieldValue('totalAdjustments'), true) || '0';
                    break;
            }

            try {
                const l = await createLoan(orgId, loan);
                trackUmami(`Create Loan (${ loan.type })`);

                history.push(`/${orgId}/loans/${ l.id }`);
            } catch (e) {
                displayErrorNotification(e);
                setSaving(false);
            }
        } catch (e) {
            console.warn('failed to validate the new loan form:', e);
            setSaving(false);
        }
    };

    const getCurrentStepItems = () => {
        switch (currentStep) {
            case 0:
                return (
                    <NewLoanStepOne
                        form={form}
                        onRelatedSelected={onRelatedSelected}
                        landInv={landInventory}
                        tracts={tracts}
                        client={client}
                        resInv={residentialInventory}
                        goNext={goNext}
                    />
                );
            case 1:
                return (
                    <NewLoanTermsStep
                        form={form}
                        goNext={goNext}
                        goBack={goBack}
                    />
                );
            case 2:
                return (
                    <NewLoanHowMuchStep
                        form={form}
                        goNext={goNext}
                        goBack={goBack}
                    />
                );
            case 3:
                return (
                    <NewLoanStepFour
                        form={form}
                        goNext={goNext}
                        goBack={goBack}
                    />
                );
            case 4:
                return (
                    <NewLoanFormEscrowDetailsStep
                        form={form}
                        goNext={goNext}
                        goBack={goBack}
                    />
                );
            case 5:
                return (
                    <NewLoanReviewStep
                        form={form}
                        resInv={residentialInventory}
                        landInv={landInventory}
                        tracts={tracts}
                        goNext={goNext}
                        goBack={goBack}
                        isSaving={isSaving}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <PageContainer {...headerProps}>
            <Card bordered={false}>
                <Form<INewLoanFormValues>
                    form={form}
                    labelWrap
                    preserve
                    onFinish={saveLoan}
                    {...formItemLayout}
                    initialValues={{
                        status: LoanStatus.Draft,
                        client: client ? { id: client.id, displayName: client.displayName } : { id: '', displayName: '' },
                        daysUntilInDefault: selectedOrg?.preferences?.loanDefaulting?.days || 30,
                        defaultingAutomaticallyEnabled: selectedOrg?.preferences?.loanDefaulting?.automatic || true,
                        daysUntilDefaulted: selectedOrg?.preferences?.loanDefaulting?.defaultsAfter || 10,
                        lateFeeDisabled: false,
                        lateFeeTiers: [],
                        paymentFrequency: PaymentFrequency.Monthly,
                        overwritePaymentAmount: false,
                        lengthUnit: LengthUnit.Years,
                        extraApplication: LoanTransactionExtraApplication.Principal,
                        isExistingLoan: false,
                        automatedCommunication: true,
                        communicationPreferences: [CommunicationPreference.SMS],
                        onlinePaymentsEnabled: true,
                        collectEscrow: false,
                        escrowStartingBalance: '0.00',
                        escrowApplicationStep: EscrowApplicationStep.BeforeInterest,
                    }}
                >
                    { getCurrentStepItems() }
                </Form>
            </Card>
        </PageContainer>
    );
};

export const NewLoan: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Loan}
        action={PermissionAction.Create}
        children={<NewLoanBase />}
    />
);
