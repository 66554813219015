import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { Card, Row, Col, Form, Steps, Button, ColProps } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

import { Loading } from 'components/misc/loading';
import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { sanitizeCurrency } from 'utils/numberFormatting';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

import { InventoryCategory, InventoryType, IInventory, IResidentialDetails, ResidentialDetailsLotAreaUnit } from 'models/inventory';
import { IPostalAddress } from 'models/common/postalAddress';
import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';
import { getUser } from 'store/selectors/auth';

import { createInventory } from 'api/inventories';

import { INewInventoryFormValues } from './newInventoryFormValues';

import { NewInventoryFormStepOne } from './firstStep';
import { InventoryDetailsFormStepTwo } from 'components/inventories/inventoryDetailsStep';
import { NewInventoryFormStepThree } from './thirdStep';

import './newInventory.css';

interface INewInventoryProps { }

export const NewInventory: React.FC<INewInventoryProps> = () => {
    const store = useStore<GlobalState>();
    const history = useHistory();
    const { orgId } = useParams<IOrgIdPathParams>();
    const [form] = Form.useForm<INewInventoryFormValues>();
    const [currentStep, setCurrentStep] = useState(0);
    const [isSaving, setSaving] = useState(false);

    //#region next, previous, save
    const goNext = () => {
        let nextStep = currentStep + 1;
        if (nextStep >= 3) {
            nextStep = 2;

            saveInventory();
        }

        setCurrentStep(nextStep);
    }

    const goBack = () => {
        let previousStep = currentStep - 1;
        if (previousStep < 0) {
            previousStep = currentStep;
        }

        setCurrentStep(previousStep);
    }

    const saveInventory = () => {
        const selectedOrg = getSelectedOrg(store.getState());
        if (!getUser(store.getState()) || !selectedOrg) {
            return;
        }

        form.validateFields().then(async () => {
            setSaving(true);

            const inventory: Partial<IInventory> = {
                name: form.getFieldValue('name'),
                category: form.getFieldValue('category'),
                type: form.getFieldValue('type'),
                status: form.getFieldValue('status'),
                address: {
                    county: form.getFieldValue('county'),
                    state: form.getFieldValue('state'),
                } as IPostalAddress,
            };

            switch (inventory.category) {
                case InventoryCategory.Land:
                    inventory.landDetails = {
                        parcelNumbers: form.getFieldValue('parcelNumbers') || [],
                        totalAcres: form.getFieldValue('totalAcres'),
                        costTotal: sanitizeCurrency(form.getFieldValue('totalCostOfDevelopment')),
                        costPerAcre: sanitizeCurrency(form.getFieldValue('perAcreCostOfDevelopment')),
                    };
                    break;
                case InventoryCategory.Residential:
                    inventory.address!.streetAddresses = [form.getFieldValue('address1')];
                    inventory.address!.city = form.getFieldValue('city');
                    inventory.address!.zipCode = form.getFieldValue('zip');

                    if (form.getFieldValue('address2')) {
                        inventory.address!.streetAddresses.push(form.getFieldValue('address2'));
                    }

                    inventory.residentialDetails = {
                        parcelNumbers: form.getFieldValue('parcelNumbers') || [],
                        bedrooms: form.getFieldValue('bedrooms'),
                        bathrooms: form.getFieldValue('bathrooms'),
                        livingSquareFootage: form.getFieldValue('livingSquareFootage'),
                        lotArea: form.getFieldValue('lotArea'),
                        lotAreaUnit: form.getFieldValue('lotAreaUnit'),
                        price: sanitizeCurrency(form.getFieldValue('residentialPrice')),
                    } as IResidentialDetails;

                    if (inventory.type === InventoryType.Rental) {
                        inventory.residentialDetails.rent = sanitizeCurrency(form.getFieldValue('rentAmount')) || '0';
                    }
                    break;
            }

            try {
                const res = await createInventory(selectedOrg.id, inventory);
                trackUmami(`Create Inventory (${ form.getFieldValue('category') } - ${ form.getFieldValue('type') })`);

                history.push(`/${ selectedOrg.shortId }/inventories/${res.id}`);
            } catch (e) {
                displayErrorNotification(e);
                setSaving(false);
            }
        });
    }
    //#endregion next, previous, save

    //#region header
    const getBreadcrumbProps = (): BreadcrumbProps => {
        const selectedOrg = getSelectedOrg(store.getState());
        if (!getUser(store.getState()) || !selectedOrg) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ orgId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ orgId }/inventories`,
                    breadcrumbName: `${ selectedOrg.name } Inventories`,
                },
                {
                    path: `/${ orgId }/inventory/new`,
                    breadcrumbName: 'New Inventory',
                },
            ],
        };
    };

    const headerProps: PageContainerProps = {
        title: 'New Inventory',
        onBack: () => history.push(`/${ orgId }/inventories`),
        breadcrumb: getBreadcrumbProps(),
    };
    //#endregion header

    //#region form related items
    const labelCol: ColProps = {
        xs: { span: 24 }, sm: { span: 8 }, lg: { span: 5 }, xl: { span: 4 },
    };

    const wrapperCol: ColProps = {
        xs: { span: 24 }, sm: { span: 16 }, lg: { span: 12, offset: 1 }, xl: { span: 6, offset: 0 },
    };

    const getCurrentStepItems = () => {
        switch (currentStep) {
            case 0:
                return (
                    <NewInventoryFormStepOne form={form} />
                );
            case 1:
                return (
                    <InventoryDetailsFormStepTwo form={form} />
                );
            case 2:
                return (
                    <Row>
                        <Col span={20} offset={2}>
                            <NewInventoryFormStepThree form={form} />
                        </Col>
                    </Row>
                );
            default:
                return null;
        }
    };

    const isNextDisabled = (): boolean => {
        const { getFieldValue } = form;

        if (currentStep === 0) {
            return !['category', 'type', 'status'].every((key) => !!getFieldValue(key));
        } else if (currentStep === 1) {
            const category = getFieldValue('category');

            switch (category) {
                case InventoryCategory.Land:
                    return !['name', 'county', 'state', 'totalAcres'].every((key) => !!getFieldValue(key));
                case InventoryCategory.Residential:
                    return !['name', 'address1', 'city', 'county', 'state', 'zip'].every((key) => !!getFieldValue(key));
            }
        }

        return isSaving;
    }

    const getButtonsRow = () => {
        const isSaveStep = currentStep === 2;
        let nextIcon = <ArrowRightOutlined />;
        if (isSaving) {
            nextIcon = <LoadingOutlined />;
        } else if (isSaveStep) {
            nextIcon = <SaveOutlined />;
        }

        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {() => (
                    <Row className="steps-row">
                        <Col span={20} offset={2}>
                            <Button.Group>
                                <Button icon={<ArrowLeftOutlined />} onClick={goBack} disabled={currentStep === 0 || isSaving}>Previous</Button>
                                <Button type="primary" onClick={goNext} disabled={isNextDisabled()}>{isSaveStep ? 'Save' : 'Next'} {nextIcon}</Button>
                            </Button.Group>
                        </Col>
                    </Row>
                )}
            </Form.Item>
        );
    };
    //#endregion form related items

    // display a loading indicator if no organization is loaded yet
    if (!getSelectedOrg(store.getState())) {
        return (
            <Loading />
        );
    }

    return (
        <PageContainer {...headerProps}>
            <Card bordered={false}>
                <Row className="steps-row">
                    <Col span={20} offset={2}>
                        <Steps
                            current={currentStep}
                            items={[
                                { title: 'Basic Data', description: 'Enter the basic data' },
                                { title: 'Details', description: 'Add further details' },
                                { title: 'Save', description: 'Review & save', icon: isSaving ? <LoadingOutlined /> : null },
                            ]}
                        />
                    </Col>
                </Row>

                <Form<INewInventoryFormValues>
                    form={form}
                    colon={false}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    preserve
                    initialValues={{
                        name: '',
                        type: InventoryType.Finance,
                        bedrooms: 0,
                        bathrooms: 0,
                        livingSquareFootage: 0,
                        lotArea: 0,
                        lotAreaUnit: ResidentialDetailsLotAreaUnit.Acres,
                        parcelNumbers: [],
                    }}
                >
                    { getCurrentStepItems() }

                    { getButtonsRow() }
                </Form>
            </Card>
        </PageContainer>
    );
}
