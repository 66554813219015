import React from 'react';
import { Form, FormInstance, Radio, Select } from 'antd';

import { InventoryStatus, InventoryCategory, InventoryType } from 'models/inventory';

import { INewInventoryFormValues } from './newInventoryFormValues';

interface INewInventoryStepOneProps {
    form: FormInstance;
}

export const NewInventoryFormStepOne: React.FC<INewInventoryStepOneProps> = ({ form }) => {
    const categoryInfo = (
        <React.Fragment>
            For more information about the different categories, please <a href={`${ process.env.REACT_APP_DOCS_URL }/app/faq`} target="_blank" rel="noopener noreferrer">see here</a>.
        </React.Fragment>
    );

    const onCategoryChange = (val: InventoryCategory) => {
        if (val !== InventoryCategory.Land) {
            return;
        }

        form.setFieldValue('type', InventoryType.Finance);
    }

    return (
        <React.Fragment>
            <Form.Item name="category" label="Category" extra={categoryInfo} rules={[{ required: true, message: 'Please select a category.' }]}>
                <Select<InventoryCategory> placeholder="Please select a category" onChange={onCategoryChange}>
                    <Select.Option value={InventoryCategory.Land}>Land</Select.Option>
                    <Select.Option value={InventoryCategory.Residential}>Residential</Select.Option>
                    <Select.Option value={InventoryCategory.Commercial} disabled>Commercial</Select.Option>
                    <Select.Option value={InventoryCategory.Other} disabled>Other</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prev: INewInventoryFormValues, curr: INewInventoryFormValues) => prev.category !== curr.category}>
                {({ getFieldValue }) => {
                    const isLand = getFieldValue('category') === InventoryCategory.Land;

                    return (
                        <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the inventory type.' }]}>
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={InventoryType.Finance}>Finance</Radio.Button>
                                <Radio.Button value={InventoryType.Rental} disabled={isLand}>Rental</Radio.Button>
                                <Radio.Button value={InventoryType.Other} disabled>Other</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select the inventory status.' }]}>
                <Select<InventoryStatus> placeholder="Status">
                    <Select.Option value={InventoryStatus.Idea}>Idea</Select.Option>
                    <Select.Option value={InventoryStatus.Ready}>Ready</Select.Option>
                    <Select.Option value={InventoryStatus.InProgress}>In Progress</Select.Option>
                    <Select.Option value={InventoryStatus.Completed}>Completed</Select.Option>
                </Select>
            </Form.Item>
        </React.Fragment>
    );
}
