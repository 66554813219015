import React from 'react';
import currency from 'currency.js';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, notification, Table } from 'antd';
import { ClockCircleOutlined, CreditCardOutlined, DeleteOutlined, EditOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import type { ExpandedRowRender } from 'rc-table/lib/interface';

import { IInventory } from 'models/inventory';
import { IPropertyTax, IPropertyTaxForTract, PropertyTaxStatus } from 'models/propertyTax';
import { Currency } from 'models/currency';
import { ILoan } from 'models/loan';
import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';

import { NewTransactionModal } from 'components/loans/newTransactionModal';
import { ActionMenuItemMap, ActionsMenu } from 'components/actions';
import { displayErrorNotification } from 'utils/errors';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { displayConfirmModal } from 'utils/modals';

import { getPropertyTaxes, deletePropertyTax, sendPropertyTaxReminder } from 'api/propertyTaxes';
import { getTractByID } from 'api/tracts';
import { getLoanByID } from 'api/loans';

import { PropertyTaxStatusTag } from './taxStatus';
import { RecordTaxPaymentModal } from './recordTaxPayment';

interface IInventoryTaxTableProps extends RouteComponentProps<IOrgIdPathParams> {
    orgId: string;
    inventory: IInventory;
}

interface IInventoryTaxTableState {
    taxes: IPropertyTax[];
    loading: boolean;
    recordVisible: boolean;
    selectedTractLoanRecord?: ILoan;
    taxRecord?: IPropertyTax;
    tractTaxRecord?: IPropertyTaxForTract;
}

class InventoryTaxTableBase extends React.PureComponent<IInventoryTaxTableProps, IInventoryTaxTableState> {
    state: Readonly<IInventoryTaxTableState> = {
        taxes: [],
        loading: true,
        recordVisible: false,
    };

    componentDidMount() {
        this.loadTaxData();
    }

    loadTaxData = async () => {
        this.setState({ loading: true });

        try {
            const res = await getPropertyTaxes(this.props.orgId, this.props.inventory.id);

            this.setState({ taxes: res, loading: false });
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onAddClick = () => this.props.history.push(`/${ this.props.orgId }/inventories/${ this.props.inventory.id }/property-taxes/new`);

    columns: TableColumnsType<IPropertyTax> = [
        {
            title: 'Year', key: 'year', dataIndex: 'taxYear',
            render: (value: number, record: IPropertyTax) => {
                return (
                    <React.Fragment>
                        { value }&nbsp;<PropertyTaxStatusTag status={record.status} />
                    </React.Fragment>
                );
            }
        },
        {
            title: 'Total Owed', key: 'totalOwed', dataIndex: 'totalOwed',
            render: (value: Currency) => currency(value, { precision: 2 }).format(true),
        },
        {
            title: 'Total Collected', key: 'totalCollected', dataIndex: 'totalCollected',
            render: (value: Currency) => value ? currency(value, { precision: 2 }).format(true) : '-',
        },
        {
            title: 'Outstanding Due', key: 'oustandingDue',
            render: (value: unknown, record: IPropertyTax) => currency(record.totalOwed, { precision: 2 }).subtract(record.totalCollected).format(true),
        },
        {
            title: 'Number of Acres', key: 'numberOfAcres',
            render: (value: unknown, record: IPropertyTax) => record.tracts.reduce((acres, tract) => acres.add(tract.acres), currency(0, { precision: 2 })).format(false),
        },
        {
            title: 'Due Date', key: 'dueDate', dataIndex: 'dueDate',
            render: (value: string) => <SimpleDate date={value} />,
        },
        {
            title: <Button size="small" type="dashed" icon={<PlusOutlined />} onClick={this.onAddClick}>Add Property Tax</Button>,
            key: 'actions', fixed: 'right', className: 'actionsTableHeader',
            render: (value: any, record: IPropertyTax) => <ActionsMenu<IPropertyTax> record={record} actions={this.yearActions} />,
        },
    ];

    onYearTaxDeleteClick = async (t: IPropertyTax) => {
        if (!await displayConfirmModal(`Remove ${ t.taxYear }'s Property Tax?`, 'Are you sure you wish to remove it? Doing so will not refund any payments made.', 'Yes', 'Nope!')) {
            return;
        }

        this.setState({ loading: true }, async () => {
            try {
                await deletePropertyTax(this.props.orgId, this.props.inventory.id, t.id);
                await this.loadTaxData();

                notification.success({ message: `${ t.taxYear } Property Tax Successfully deleted!` });
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    onYearTaxEditClick = async (t: IPropertyTax) => this.props.history.push(`/${ this.props.match.params.orgId }/inventories/${ this.props.inventory.id }/property-taxes/${ t.id }`);

    yearActions: ActionMenuItemMap<IPropertyTax> = {
        edit: { text: 'Edit', icon: <EditOutlined />, disabled: (t) => t.status !== PropertyTaxStatus.Draft, action: this.onYearTaxEditClick },
        delete: { text: 'Delete', icon: <DeleteOutlined />, disabled: (t) => t.totalCollected === t.totalOwed || this.state.loading, action: this.onYearTaxDeleteClick },
    };

    showRecordTransactionFor = async (propTax: IPropertyTax, tractTax: IPropertyTaxForTract) => {
        this.setState({ loading: true }, async () => {
            try {
                const tract = await getTractByID(this.props.orgId, this.props.inventory.id, tractTax.tractId);

                if (!tract.loan || !tract.loan.id) {
                    this.setState({ loading: false, recordVisible: true, taxRecord: propTax, tractTaxRecord: tractTax });
                    return;
                }

                const loan = await getLoanByID(this.props.orgId, tract.loan.id);

                this.setState({ loading: false, recordVisible: true, selectedTractLoanRecord: loan, tractTaxRecord: tractTax });
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ loading: false });
            }
        });
    }

    sendPropertyTaxReminder = async (propTax: IPropertyTax, tractTax: IPropertyTaxForTract) => {
        this.setState({ loading: true }, async () => {
            try {
                await sendPropertyTaxReminder(this.props.orgId, this.props.inventory.id, propTax.id, { tractId: tractTax.tractId });

                notification.success({ message: `Successfully sent a reminder for ${ tractTax.tractLabel }'s ${ propTax.taxYear } Property Tax.` });

                await this.loadTaxData();
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    closeAddTransaction = async (saved: boolean) => {
        if (saved) {
            await this.loadTaxData();
        }

        this.setState({ recordVisible: false, selectedTractLoanRecord: undefined, tractTaxRecord: undefined });
    }

    taxTableTractRender: ExpandedRowRender<IPropertyTax> = (value: IPropertyTax) => {
        const yearTractActions: ActionMenuItemMap<IPropertyTaxForTract> = {
            viewTract: { text: 'View Tract', icon: <FileOutlined />, action: async (t) => this.props.history.push(`/${ this.props.match.params.orgId }/inventories/${ this.props.inventory.id }/tracts/${ t.tractId }`) },
            remind: { text: 'Remind', icon: <ClockCircleOutlined />, disabled: this.state.loading || value.status !== PropertyTaxStatus.InProgress, action: (pt) => this.sendPropertyTaxReminder(value, pt) },
            record: { text: 'Record Payment', icon: <CreditCardOutlined />, disabled: (pt) => this.state.loading || !pt.partOfTaxBill || currency(pt.amountOwed, { precision: 2 }).value === currency(pt.amountPaid, { precision: 2 }).value, action: (pt) => this.showRecordTransactionFor(value, pt), },
        };

        const expandedColumns: TableColumnsType<IPropertyTaxForTract> = [
            {
                title: 'Tract', key: 'label', dataIndex: 'tractLabel',
            },
            {
                title: 'Amount Owed', key: 'amountOwed', dataIndex: 'amountOwed',
                render: (value: Currency, record: IPropertyTaxForTract) => <LongCurrency value={record.partOfTaxBill ? value : undefined} precision={2} />,
            },
            {
                title: 'Amount Paid', key: 'amountPaid', dataIndex: 'amountPaid',
                render: (value: Currency, record: IPropertyTaxForTract) => <LongCurrency value={record.partOfTaxBill ? value : undefined} precision={2} />,
            },
            {
                title: 'Notice Letter Date', key: 'noticeLetterSentDate', dataIndex: 'noticeLetterSentDate',
                render: (value: string) => <SimpleDate date={value} />,
            },
            {
                title: 'Notice Text Date', key: 'noticeTextSentDate', dataIndex: 'noticeTextSentDate',
                render: (value: string) => <SimpleDate date={value} />,
            },
            {
                title: 'Date Paid', key: 'datePaid', dataIndex: 'datePaid',
                render: (value: string) => <SimpleDate date={value} />,
            },
            {
                title: '', key: 'expandedActions',
                render: (value: any, record: IPropertyTaxForTract) => record.partOfTaxBill ? <ActionsMenu<IPropertyTaxForTract> record={record} actions={yearTractActions} /> : null,
            },
        ];

        return (
            <Table<IPropertyTaxForTract>
                columns={expandedColumns}
                dataSource={value.tracts}
                pagination={false}
                size="small"
                rowKey="tractId"
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <Table<IPropertyTax>
                    columns={this.columns}
                    dataSource={this.state.taxes}
                    loading={this.state.loading}
                    rowKey="id"
                    expandable={{ expandedRowRender: this.taxTableTractRender }}
                    pagination={{ hideOnSinglePage: true }}
                />

                <RecordTaxPaymentModal
                    propertyTax={this.state.taxRecord}
                    tractRecord={this.state.tractTaxRecord}
                    visible={this.state.recordVisible && typeof this.state.selectedTractLoanRecord === 'undefined'}
                    close={this.closeAddTransaction}
                />

                <NewTransactionModal
                    taxRecord={this.state.tractTaxRecord}
                    isVisible={this.state.recordVisible}
                    loan={this.state.selectedTractLoanRecord}
                    close={this.closeAddTransaction}
                />
            </React.Fragment>
        );
    }
}

export const InventoryTaxTable = withRouter(InventoryTaxTableBase);
