import React from 'react';
import currency from 'currency.js';
import { Card, Table } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { getRecentTransactions } from 'api/dashboard';
import { IRecentTransaction } from 'models/dashboard';

import { SimpleDate } from 'utils/formatting';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

interface IRecentTransactionsCardProps {
    orgId: string;
    orgShortId: string;
}

interface IRecentTransactionsCardState {
    loading: boolean;
    fetchingMore: boolean;
    total: number;
    currentPage: number;
    transactions: IRecentTransaction[];
}

const pageSize = 5;

export class RecentTransactionsCard extends React.PureComponent<IRecentTransactionsCardProps, IRecentTransactionsCardState> {
    state: Readonly<IRecentTransactionsCardState> = {
        loading: true,
        fetchingMore: false,
        total: 0,
        currentPage: 1,
        transactions: [],
    };

    columns: ColumnProps<IRecentTransaction>[] = [
        {
            title: 'Date', dataIndex: ['date'], key: 'date',
            render: (date: string) => <SimpleDate date={date} simplier />,
        },
        {
            title: 'Loan', dataIndex: ['loan', 'label'], key: 'loanLabel',
            render: (loanLabel: string, trans) => <Link to={`/${ this.props.orgShortId }/loans/${ trans.loan.id }`}>{ loanLabel }</Link>
        },
        {
            title: 'Type', dataIndex: ['type'], key: 'type', className: 'title-caps',
            render: (type: string) => type ? type.replaceAll('-', ' ') : '-',
        },
        {
            title: 'Status', dataIndex: ['status'], key: 'status', align: 'center', className: 'title-caps',
            render: (status: string) => status ? status.replaceAll('-', ' ') : '-',
        },
        {
            title: 'Amount', dataIndex: ['amount'], key: 'amount', align: 'right',
            render: (amount: string) => currency(amount, { precision: 2 }).format(true),
        }
    ];

    componentDidMount() {
        if (this.props.orgId) {
            this.loadRecentTransactions();
        }
    }

    componentDidUpdate(prevProps: IRecentTransactionsCardProps) {
        if (prevProps.orgId === this.props.orgId) {
            return;
        }

        this.setState({ currentPage: 1, total: 0, fetchingMore: true }, () => this.loadRecentTransactions());
    }

    onPageChange = (page: number) => {
        this.setState({ currentPage: page }, () => {
            this.loadRecentTransactions();
        });

        trackUmami('Dashboard Recent Transactions Page Change');
    }

    loadRecentTransactions = () => {
        this.setState({ fetchingMore: true }, async () => {
            try {
                const res = await getRecentTransactions(this.props.orgId, (this.state.currentPage - 1) * pageSize);

                this.setState({
                    total: res.pagination!.count,
                    transactions: res.data,
                });
            } catch (e) {
                displayErrorNotification(e, this.loadRecentTransactions);
            } finally {
                this.setState({ loading: false, fetchingMore: false });
            }
        });
    }

    render() {
        return (
            <Card loading={this.state.loading} bordered={false} title="Recent Transactions" style={{ height: '100%' }}>
                <Table<IRecentTransaction>
                    size="small"
                    loading={this.state.fetchingMore}
                    columns={this.columns}
                    dataSource={this.state.transactions}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        style: { marginBottom: 0 },
                        pageSize,
                        showSizeChanger: false,
                        current: this.state.currentPage,
                        total: this.state.total,
                        onChange: this.onPageChange
                    }}
                />
            </Card>
        );
    }
}
