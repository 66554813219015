import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isMobileOnly } from 'react-device-detect';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import type { BrowserHistoryBuildOptions } from 'history';
import { IconContext } from 'react-icons';
import { App, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';

import { store, persistor } from 'store';

import { ErrorBoundary } from 'layout/errorBoundary';
import { Loading } from 'components/misc/loading';
import { UmamiAnalytics } from 'components/misc/umami';
import { registerUmamiScript } from 'components/misc/registerUmami';
import { ClientVersionChecker } from 'components/misc/version';
import { ConnectivityBanner } from 'components/misc/connectivity';
import { SentryUser } from 'components/misc/sentryUser';

import NoAuthLayout from 'layout/noauth';
import { MainLayout } from 'layout/main';
import FullScreenLayout from 'layout/fullscreen';

import { hideHelpScout } from 'utils/helpscout';

let browserHistoryOptions: BrowserHistoryBuildOptions | undefined = undefined;
if (process.env.REACT_APP_BASEURL?.includes('/proxy/3000')) {
    browserHistoryOptions = { basename: '/proxy/3000' };
}

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory(browserHistoryOptions);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://11e94c3adf264f83b5b266423006073d@o1019945.ingest.sentry.io/5985667",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                tracePropagationTargets: ['localhost', 'api.lendiom.com', /^\//],
            }),
        ],
        environment: process.env.NODE_ENV,
        release: 'lendiom-ui@' + process.env.REACT_APP_VERSION,
        tracesSampleRate: 0.5, //TODO: in the future, change this up
    });
}

const AppRouter: React.FC = () => {
    useEffect(() => {
        registerUmamiScript('https://uem.fidetech.io/umami.js', process.env.REACT_APP_UMAMI_WEBSITE_ID!);

        if (isMobileOnly) {
            hideHelpScout();
        }
    }, []);

    return (
        <React.Fragment>
            <ConnectivityBanner />

            <Provider store={store}>
                <ErrorBoundary>
                    <PersistGate loading={<Loading />} persistor={persistor}>
                        <IconContext.Provider value={{ className: 'react-icons' }}>
                            <ConfigProvider locale={enUS}>
                                <App>
                                    <Router history={history}>
                                        <Switch>
                                            <Redirect exact from="/login" to="/auth/login" />
                                            <Redirect exact from="/register" to="/auth/register" />

                                            <SentryRoute path="/auth" component={NoAuthLayout} />
                                            <SentryRoute exact path="/org/new" component={FullScreenLayout} />
                                            <SentryRoute path="/:orgId?" component={MainLayout} />
                                        </Switch>

                                        <UmamiAnalytics />
                                    </Router>

                                    <SentryUser />
                                    <ClientVersionChecker />
                                </App>
                            </ConfigProvider>
                        </IconContext.Provider>
                    </PersistGate>
                </ErrorBoundary>
            </Provider>
        </React.Fragment>
    );
}

export default Sentry.withProfiler(AppRouter);
