import { Currency } from './currency';

import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { ILookup } from './common/lookup';
import { IEscrow } from './escrow';
import { ILateFeeConfig } from './lateFee';
import { TransactionMethod, TransactionStatus, TransactionReversalReason } from './transactions';
import { CommunicationPreference, LengthUnit, OnlinePaymentPlatformFeePayee, PaymentFrequency } from './';
import { PaymentMethodSource } from './billing/paymentMethod';
import { ILoanDownPayment } from './loanDownPayment';

export interface ILoan extends IObjectDates, IOrganizational {
    id: string;
    label: string;

    type: LoanType;
    residential?: ILoanResidential;
    tracts?: LoanTract[];
    client: ILookup;
    status: LoanStatus;
    communication: LoanCommunication;
    onlinePaymentConfig: ILoanOnlinePaymentConfiguration;

    terms: ILoanTerms;
    balance: ILoanBalance;
    lateFeeConfig: ILateFeeConfig;
    defaulting: ILoanDefaulting;
    hasSchedule: boolean;

    closingDate: string;
    downPaymentDate?: string; // only populated when interest accrues daily and first payment basis is down payment
    firstPaymentDate: string;
    previousDueDate: string;
    lastPaymentReceivedDate: string;
    lastTransactionDate: string;
    lastTransactionModifiedDate: string;
    nextDueDate: string;
    repossessedDate: string;

    downPayment?: ILoanDownPayment;
    escrow?: IEscrow;
}

export enum LoanType {
    Tract = 'tract',
    Residential = 'residential',
    Cash = 'cash',
}

export enum LoanStatus {
    Draft = 'draft',
    Pending = 'pending',
    Current = 'active',
    GracePeriod = 'grace-period',
    Late = 'late',
    InDefault = 'in-default',
    Defaulted = 'defaulted',
    PendingPayoff = 'pending-payoff',
    PaidOff = 'paid-off',
    Repossessed = 'repo',
    Inactive = 'inactive',
    Canceled = 'canceled',
}

export interface ILoanResidential {
    inventoryId?: string;
    squareFootage?: number;
}

export interface LoanTract {
    inventoryId: string;
    tractId: string;
    number?: number;
}

export interface LoanCommunication {
    automated: boolean;
    preferences: CommunicationPreference[];
}

export interface ILoanOnlinePaymentConfiguration {
    enabled: boolean;
    statementDescriptor: string;
    platformFeePayee: OnlinePaymentPlatformFeePayee;
    allowPrincipalOnly: boolean;
    allowAutoDraft: boolean;

    autoDraft?: ILoanOnlineAutoDraftConfiguration;
}

export interface ILoanOnlineAutoDraftConfiguration {
    enabled: boolean;
    extraApplication: LoanTransactionExtraApplication;
    paymentAmount: Currency;
    paymentMethodId: string;
    nextDate: string;
    previousDate: string;
}

//#region loan terms
export interface ILoanTerms {
    downPayment: Currency;
    salesPrice: Currency;
    adjustments?: Currency;
    amount?: Currency;
    length: number;
    lengthUnit: LengthUnit;
    rate: Currency;
    interestSchedule: LoanTermsInterestSchedule;
    interestFormula?: LoanTermsInterestFormula;
    firstPaymentBasis?: LoanTermsInterestAccruesDailyFirstPaymentBasis; //also called Finance Start
    payment?: Currency;
    overwritePayment?: boolean;
    paymentFrequency: PaymentFrequency;
    extraApplication: LoanTransactionExtraApplication;
    wasExisting: boolean;
    existing: ILoanTermsExisting;
}

export interface ILoanTermsExisting {
    principalPaid: Currency;
    interestPaid: Currency;
    interestPaidYtd: Currency;
    unpaidInterest?: Currency;
    lastPaidInFullPayment?: number;
    lastPaymentDate: string;
    nextPaymentDate: string;
}

export enum LoanTermsInterestSchedule {
    FollowsPayments = 'follows-payments',
    AccruesDaily = 'accrues-daily',
}

export enum LoanTermsInterestFormula {
    ThirtyDaysBy360 = '30/360',
    ActualBy360 = 'actual/360',
    ActualBy365 = 'actual/365',
}

export enum LoanTermsInterestAccruesDailyFirstPaymentBasis {
    ClosingDate = 'closing-date',
    DownPaymentDate = 'down-payment-date',
    FullMonth = 'full-month',
}
//#endregion loan terms

export interface ILoanBalance {
    principal: Currency;
    lateFees: Currency;
    due: Currency;
    propertyTax: Currency;
    otherFees: Currency;
    interest?: Currency; //interest is unpaid interest balance from previous payments
    accruedInterest?: Currency; //accruedInterest is the amount accrued since the last payment
    totalDue?: Currency; //totalDue is everything due, excluding accrued interest.
}

export interface ILoanDefaulting {
    automatic: boolean; //whether or not to automatically move the loan to in-default status
    days: number; //the number of days until the loan is considered in-default
    defaultsAfter: number; //the number of days after the loan is moved to in-default status that it should be marked as defaulted
}

//#region loan transaction
export interface ILoanTransaction {
    id: string;
    date: string;
    status: TransactionStatus;
    method: TransactionMethod;
    type: LoanTransactionType;
    extraApplication: LoanTransactionExtraApplication;
    adjustmentType?: LoanTransactionAdjustmentType;
    forPayments?: number[];
    toPayments?: ILoanTransactionTo[];
    forDownPaymentIncrement?: number[];

    processor?: PaymentMethodSource;
    processorTransactionId?: string;

    amount: Currency;
    totalAmount: Currency;
    totalToPlatform: Currency;
    totalToFees: Currency;
    totalToInterest: Currency;
    toUnpaidInterest: Currency;
    totalToPrincipal: Currency;
    totalToEscrow: Currency;
    loanBalance: Currency;

    lateFeeWaived: boolean;
    lateFeeTier?: number;

    comment: string;
    commentText: string;

    statusChangedAt: string;
    reversedAt: string;
    reversalReason: TransactionReversalReason;
    reversalComment: string;
    reversalCommentText: string;
    stripeRefundId: string;

    files: ILoanTransactionFiles;

    createdBy: ILookup;
    createdAt: string;
}

export enum LoanTransactionType {
    DownPayment = 'down-payment',
    RegularPayment = 'regular-payment',
    PrincipalPayment = 'principal-payment',
    LateFee = 'late-fee',
    OtherFee = 'other-fee',
    PropertyTax = 'property-tax',
    DocumentationFee = 'documentation-fee',
    ClosingFee = 'closing-fee',
    EarnestMoney = 'earnest-money',
    Adjustment = 'adjustment',
    Payoff = 'payoff',
}

export enum LoanTransactionExtraApplication {
    Principal = 'principal',
    NextPayment = 'next-payment',
}

export enum LoanTransactionAdjustmentType {
    Interest = 'interest',
    Principal = 'principal',
    Payment = 'payment',
}

export interface ILoanTransactionTo {
    paymentNumber: number;
    interest: Currency;
    principal: Currency;
    escrow: Currency;
}

export interface ILoanTransactionFiles {
    lateNoticeId: string;
    paymentSuccessId: string;
}
//#endregion loan transaction

export interface ILoanPayOff {
    total: Currency;
    toInterest: Currency;
    toUnpaidInterest: Currency;
    toPrincipal: Currency;
    toFees: Currency;
    toOtherFees: Currency;
    toPropertyTax: Currency;
    toEscrow: Currency;
    letterUrl?: string;
}
