import React from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import currency from 'currency.js';
import shortid from 'shortid';
import debounce from 'lodash.debounce';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { BreadcrumbProps, Button, Card, Col, DatePicker, Form, FormInstance, Input, notification, Popover, Result, Row, Table, Typography } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { ColumnProps } from 'antd/lib/table';
import { CheckOutlined, SaveOutlined, SendOutlined, WarningTwoTone } from '@ant-design/icons';

import { ITract, TractStatus } from 'models/tract';
import { IPropertyTax, IPropertyTaxForTract, PropertyTaxStatus } from 'models/propertyTax';
import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';
import { getInventoryById } from 'store/selectors/inventories';
import { fetchInventory } from 'store/actions/inventories';

import { getTracts } from 'api/tracts';
import { createPropertyTax, getPropertyTaxById, updatePropertyTaxById } from 'api/propertyTaxes';

import { FinalizePropertyTax } from 'components/taxes/finalizeDrawer';
import { PropertyTaxStatusTag } from 'components/taxes/taxStatus';
import { Loading } from 'components/misc/loading';

import { displayErrorNotification } from 'utils/errors';
import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { SimpleTooltip } from 'utils/tooltipWrapper';
import { displayConfirmModal } from 'utils/modals';
import { sanitizeCurrency } from 'utils/numberFormatting';

interface ITaxPathParams extends IOrgIdPathParams {
    inventoryId: string;
    propertyTaxId: string;
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<ITaxPathParams>) => ({
    selectedOrg: getSelectedOrg(state),
    inventoryId: getInventoryById(state, props.match.params.inventoryId),
});

interface IPropertyTaxProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<ITaxPathParams> {}

type taxTract = ITract & { taxCost: string, customized: boolean };

interface IPropertyTaxState {
    loading: boolean;
    saving: boolean;
    finalizing: boolean;
    notFound: boolean;
    otherError: boolean;
    error: string;
    valueChangeEventId: string;
    tracts: taxTract[];
    selectedTracts: React.Key[];
    editingTaxRecord?: IPropertyTax;
    updatedAt?: Date;
}

interface ITaxFormValues {
    year: Dayjs;
    totalOwed: string;
    perAcre: string;
    dueDate: Dayjs;
    customized: { [key: string ]: string };
    valueChangeEventId: string;
}

class PropertyTaxBase extends React.PureComponent<IPropertyTaxProps, IPropertyTaxState> {
    state: Readonly<IPropertyTaxState> = {
        loading: true,
        saving: false,
        finalizing: false,
        notFound: false,
        otherError: false,
        error: '',
        valueChangeEventId: '',
        tracts: [],
        selectedTracts: [],
    };

    formRef = React.createRef<FormInstance<ITaxFormValues>>();

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<IPropertyTaxProps>, prevState: Readonly<IPropertyTaxState>): void {
        if (prevState.loading && !this.state.loading && this.formRef.current) {
            this.setState({ valueChangeEventId: shortid() });
            console.log('updating the state to a new value change event');
        }

        if (prevProps.match.params.propertyTaxId !== this.props.match.params.propertyTaxId) {
            this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true, notFound: false, otherError: false, saving: false, finalizing: false });
        const { orgId, inventoryId } = this.props.match.params;

        try {
            await this.props.dispatch(fetchInventory(inventoryId) as any);

            const tracts = await getTracts(orgId, inventoryId);

            if (this.props.match.params.propertyTaxId === 'new') {
                await this.mapTractsToTaxTract(tracts);
                const selectedTracts = tracts.filter((t) => t.taxReimbursementEligibility).map((t) => t.id);

                this.setState({ selectedTracts: selectedTracts, loading: false });
            } else {
                const taxRecord = await getPropertyTaxById(orgId, inventoryId, this.props.match.params.propertyTaxId);

                this.setState({
                    loading: false,
                    editingTaxRecord: taxRecord,
                    tracts: tracts.map((tract) => {
                        const tt: taxTract = {
                            ...tract,
                            taxCost: '',
                            customized: false,
                        };

                        const existing = taxRecord.tracts.find((t) => t.tractId === tract.id);
                        if (!existing) {
                            return tt;
                        }

                        tt.taxCost = existing.amountOwed;
                        tt.customized = existing.customAmount;

                        return tt;
                    }),
                    selectedTracts: taxRecord.tracts.filter((t) => t.partOfTaxBill).map((t) => t.tractId),
                }, () => this.formRef.current?.resetFields());
            }
        } catch (e: any) {
            console.error('Error while loading the inventory:', e);
            displayErrorNotification(e);

            if (e.code === 73) {
                this.setState({ notFound: true, loading: false });
            } else {
                this.setState({
                    notFound: false,
                    otherError: true,
                    error: typeof e.error === 'string' ? e.error : 'Unknown error',
                    loading: false,
                });
            }
        }
    }

    onFormSubmit = async (): Promise<void> => {
        if (!this.formRef.current) {
            console.warn('no current form?');
            return;
        }

        let values: ITaxFormValues;
        try {
            values = await this.formRef.current.validateFields();
        } catch (e) {
            return;
        }

        const totalOwed = currency(values.totalOwed);
        if (totalOwed.value === 0 && await displayConfirmModal('Continue?', 'The total amount owed is marked as $0.00. Are you sure you wish to create a property tax with nothing owed?', 'Yes, continue', 'Whoops, no!!')) {
            return;
        }

        let totalFromTracts = currency(0, { precision: 12 });
        this.state.tracts.forEach((t) => totalFromTracts = totalFromTracts.add(t.taxCost));
        totalFromTracts = currency(totalFromTracts, { precision: 2 });// round it for display
        if (totalFromTracts.value > totalOwed.value) {
            displayConfirmModal('Too Much Owed!', `The combined amount owed from each tract is more than the Total Owed. The combined total is ${ totalFromTracts.format(true) } and the total owed is ${ totalOwed.format(true) }. Please fix this and try again.`, null, 'Okay!');
            return;
        }

        this.setState({ saving: true }, async () => {
            let tax: Partial<IPropertyTax> = {
                taxYear: values.year.get('year'),
                totalOwed: sanitizeCurrency(values.totalOwed),
                perAcre: sanitizeCurrency(values.perAcre),
                dueDate: values.dueDate.toJSON(),
                tracts: this.state.tracts.map((t) => {
                    return {
                        tractId: t.id,
                        tractLabel: t.label,
                        acres: t.acres,
                        amountOwed: sanitizeCurrency(t.taxCost || '0.00'),
                        customAmount: t.customized,
                        partOfTaxBill: this.state.selectedTracts.includes(t.id),
                    } as IPropertyTaxForTract;
                }),
            };

            if (this.state.editingTaxRecord) {
                tax = Object.assign(this.state.editingTaxRecord, tax);
            }

            try {
                const { orgId, inventoryId } = this.props.match.params;

                if (this.state.editingTaxRecord) {
                    await updatePropertyTaxById(orgId, inventoryId, this.state.editingTaxRecord.id, tax);
                    notification.success({ message: `Successfully updated the ${ tax.taxYear } Property Tax record!` });

                    this.setState({ saving: false });
                } else {
                    const res = await createPropertyTax(orgId, inventoryId, tax);
                    notification.success({ message: `Successfully created the ${ tax.taxYear } Property Tax record!` });

                    console.log('resulting property tax:', res);
                    this.props.history.push(`/${ this.props.selectedOrg!.shortId }/inventories/${ this.props.inventoryId!.id }/property-taxes/${ res.id }`);
                }
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ saving: false });
            }
        });
    }

    onFinalizeClick = async () => {
        if (this.state.updatedAt) {
            if (await displayConfirmModal('Continue?', `The data was last changed at ${ this.state.updatedAt.toLocaleString() }, would you like to save or discard the changes?`, 'Save', 'Discard')) {
                await this.onFormSubmit();
            } else {
                this.formRef.current?.resetFields();
            }
        }

        await this.mapTractsToTaxTract(this.state.tracts);

        this.setState({ saving: true, finalizing: true });
    }

    onFinalizeCancel = () => {
        this.setState({ saving: false, finalizing: false });
    }

    onSaveAndFinalizeClick = async () => {
        try {
            await this.onFormSubmit();
            await this.onFinalizeClick();
        } catch (e) {
            console.warn('failed to save and finalize');
        }
    }

    //#region change callbacks
    valuesChanged = (changedValues: Partial<ITaxFormValues>) => {
        this.setState({ updatedAt: new Date() });

        if (changedValues.totalOwed) {
            this.setState({ valueChangeEventId: shortid.generate(), tracts: [...this.state.tracts] });
            return;
        }

        if (changedValues.perAcre) {
            this.mapTractsToTaxTract(this.state.tracts);
            return;
        }

        if (changedValues.customized) {
            this.mapTractsToTaxTract(this.state.tracts);
        }
    }

    mapTractsToTaxTract = (tracts: ITract[] | taxTract[]): Promise<void> => {
        return new Promise((resolve) => {
            const mappedTracts = tracts.map((tract) => {
                const tt: taxTract = {
                    ...tract,
                    taxCost: '',
                    customized: false,
                };

                // if the tract is not eligible for tax reimbursement
                // then don't include it in the calculations
                if (!tract.taxReimbursementEligibility) {
                    return tt;
                }

                if (!this.formRef.current) {
                    return tt;
                }

                if (this.formRef.current.getFieldValue(['customized', tract.id])) {
                    tt.customized = true;
                    tt.taxCost = currency(this.formRef.current.getFieldValue(['customized', tract.id]), { precision: 2 }).format(false);
                } else if (this.formRef.current.getFieldValue('perAcre')) {
                    tt.taxCost = currency(this.formRef.current.getFieldValue('perAcre'), { precision: 12 }).multiply(tract.acres).format(false);
                }

                return tt;
            });

            this.setState({ tracts: mappedTracts }, () => resolve());
        });
    }

    onTotalOwedChange = debounce(() => {
        if (!this.formRef.current || !this.props.inventoryId || !this.props.inventoryId.landDetails) {
            return;
        }

        if (this.formRef.current.isFieldTouched('perAcre')) {
            return;
        }

        const totalAcresEligible = this.state.tracts.filter((t) => t.taxReimbursementEligibility).map((t) => currency(t.acres, { precision: 12 })).reduce((prev, curr) => prev.add(curr));
        const cost = currency(this.formRef.current.getFieldValue('totalOwed'), { precision: 12 }).divide(totalAcresEligible);

        this.formRef.current.setFieldValue('perAcre', cost.format(false));
        this.mapTractsToTaxTract(this.state.tracts)
    }, 500);
    //#endregion change callbacks

    //#region form fields
    get taxYearInput() {
        return (
            <Form.Item
                name="year"
                label={<SimpleTooltip content="Tax Year" title="The year this property tax bill is due." hasQuestion />}
                extra="Only one property tax record is allowed per year."
                rules={[{ required: true, message: 'Please indicate which year the property tax applies to!' }]}
            >
                <DatePicker
                    picker="year"
                    style={{ width: '100%' }}
                    disabled={this.state.loading || this.state.saving}
                />
            </Form.Item>
        );
    }

    get totalOwedInput() {
        //TODO: maybe add a validator to ensure the total owed amount is greater than zero?
        return (
            <Form.Item name="totalOwed" label="Total Owed" rules={[{ required: true, message: 'Please provide the total amount of property tax owed!' }]}>
                <Input
                    prefix="$"
                    style={{ width: '100%' }}
                    disabled={this.state.loading || this.state.saving}
                    onChange={this.onTotalOwedChange}
                />
            </Form.Item>
        );
    }

    get taxPerAcreInput() {
        return (
            <Form.Item
                name="perAcre"
                label="Tax Per Acre"
                extra="We recommend leaving this to the many decimal places otherwise the amount owed per tract will be off a few cents."
                rules={[{ required: true, message: 'Please provide the amount of tax owed per acre!' }]}
            >
                <Input
                    prefix="$"
                    style={{ width: '100%' }}
                    disabled={this.state.loading || this.state.saving}
                />
            </Form.Item>
        );
    }

    get dueDateInput() {
        return (
            <Form.Item
                name="dueDate"
                label="Due Date"
                extra="The date when the buyers must pay their reimbursement by. This is not always the same as the date you have to pay the taxes."
                rules={[{ required: true, message: 'The due date must be provided.' }]}
            >
                <DatePicker
                    picker="date"
                    format="MM/DD/YYYY"
                    style={{ width: '100%' }}
                    disabled={this.state.loading || this.state.saving}
                />
            </Form.Item>
        );
    }
    //#endregion form fields

    //#region tracts table

    resetTractTax = (tractPath: string[]) => {
        this.setState({ updatedAt: new Date() });
        this.formRef.current?.setFieldValue(tractPath, undefined);
        this.mapTractsToTaxTract(this.state.tracts);
    }

    tractsTableSummary = (tracts: readonly taxTract[]) => {
        if (!tracts || !Array.isArray(tracts)) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    let totalAcres = 0;
                    let eligibleAcres = 0;
                    let totalOwed = currency(getFieldValue('totalOwed'), { precision: 2 });
                    let totalTaxesOwed = currency(0, { precision: 12 });

                    tracts.forEach((t: taxTract) => {
                        totalAcres += t.acres;

                        if (t.taxReimbursementEligibility && this.state.selectedTracts.includes(t.id)) {
                            eligibleAcres += t.acres;
                            totalTaxesOwed = totalTaxesOwed.add(t.taxCost);
                        }
                    });

                    const totalTaxesOwedRounded = currency(totalTaxesOwed, { precision: 2 });
                    const totalTaxesOwedContent = (
                        <strong>{ totalTaxesOwedRounded.intValue === 0 ? '-' : totalTaxesOwedRounded.format(true) }</strong>
                    );

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2} />
                            <Table.Summary.Cell index={2}>
                                <strong>{ eligibleAcres === 0 ? '-' : eligibleAcres.toFixed(2) } ({ totalAcres === 0 ? '-' : totalAcres.toFixed(2) })</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <SimpleTooltip
                                    title={`Total Taxes Owed${ totalTaxesOwedRounded.value > totalOwed.value ? ' (amount is more than total due)' : '' }`}
                                    content={totalTaxesOwedRounded.value > totalOwed.value ? <Typography.Text type="danger">{ totalTaxesOwedContent }</Typography.Text> : totalTaxesOwedContent}
                                />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} />
                        </Table.Summary.Row>
                    );
                }}
            </Form.Item>
        );
    }

    get tractsTable() {
        const tractTaxTableColumns: ColumnProps<taxTract>[] = [
            {
                title: 'Label', dataIndex: 'label', key: 'label',
                render: (value: string, tract: taxTract) => {
                    // should it not be eligible for tax reimbursement, then we should show a tooltip so they know why
                    if (!tract.taxReimbursementEligibility) {
                        return (
                            <SimpleTooltip
                                title={`${ value } is marked as not eligible for property tax reimbursement.`}
                                content={value}
                                hasQuestion
                            />
                        );
                    }

                    // Should the status not be sold or not paid off then we don't show anything special
                    if (tract.status !== TractStatus.Sold && tract.status !== TractStatus.PaidOff) {
                        return value;
                    }

                    // now that the tract status must be sold or paid off, we need to check if it has a loan or not
                    // if it does have a loan then we say all is well. However, if it doesn't then we
                    // should display the warning
                    if (tract.loan.id) {
                        return value;
                    }

                    const warning = (
                        <React.Fragment>
                            { value } <WarningTwoTone twoToneColor="#f59e42" />
                        </React.Fragment>
                    );

                    return (
                        <SimpleTooltip
                            title={`${ value } is marked as ${ tract.status === TractStatus.Sold ? 'sold' : 'paid off'}, please verify this tract is still eligible for property tax reimbursement.`}
                            content={warning}
                        />
                    );
                }
            },
            {
                title: 'Acres', dataIndex: 'acres', key: 'acres',
            },
            {
                title: 'Taxes Owed', dataIndex: 'taxCost', key: 'taxesOwed',
                render: (value: string) => {
                    if (!value) {
                        return '-';
                    }

                    return currency(value, { precision: 2 }).format(true);
                },
            },
            {
                title: '', key: 'edit',
                render: (nothing: any, tract: taxTract) => {
                    const disabled = this.state.saving || !this.state.selectedTracts.includes(tract.id);
                    const btn = <Button type="link" disabled={disabled}>Edit</Button>;

                    if (disabled) {
                        return btn;
                    }

                    const tractPath = ['customized', tract.id];
                    const formItem = (
                        <Form.Item name={tractPath} noStyle>
                            <Input prefix="$" autoFocus allowClear />
                        </Form.Item>
                    );

                    return (
                        <Button.Group>
                            <Popover key={`${ tract.id }-popover`} trigger={disabled ? undefined : 'click'} content={formItem}>
                                { btn }
                            </Popover>
                            { tract.customized ? <Button type="link" onClick={() => this.resetTractTax(tractPath)}>Reset</Button> : null }
                        </Button.Group>
                    );
                },
            },
        ];

        return (
            <Table<taxTract>
                columns={tractTaxTableColumns}
                dataSource={this.state.tracts}
                summary={this.tractsTableSummary}
                rowKey="id"
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 100,
                }}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: this.state.selectedTracts,
                    getCheckboxProps: (tract) => ({ disabled: !tract.taxReimbursementEligibility || this.state.saving, name: tract.label, }),
                    onChange: (selectedRowKeys) => {
                        this.setState({ selectedTracts: selectedRowKeys, updatedAt: new Date() });
                        this.formRef.current?.setFieldsValue({ valueChangeEventId: shortid.generate() });
                    },
                }}
            />
        );
    }
    //#endregion tracts table

    //#region header
    onBack = () => this.props.history.push(`/${this.props.match.params.orgId}/inventories/${ this.props.match.params.inventoryId }`);

    get extra() {
        return (
            <Button.Group>
                { this.state.editingTaxRecord ? <Button type="primary" icon={<CheckOutlined />} loading={this.state.saving && this.state.finalizing} disabled={this.state.saving} onClick={this.onFinalizeClick}>Finalize</Button> : null }
                { !this.state.editingTaxRecord ? <Button type="default" icon={<SendOutlined />} loading={this.state.saving && this.state.finalizing} disabled={this.state.saving} onClick={this.onSaveAndFinalizeClick}>Create &amp; Finalize</Button> : null }
                <Button type={this.state.editingTaxRecord ? 'default' : 'primary'} icon={<SaveOutlined />} onClick={this.onFormSubmit} loading={this.state.saving && !this.state.finalizing} disabled={this.state.saving}>{ this.state.editingTaxRecord ? 'Update' : 'Create Draft' }</Button>
            </Button.Group>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg || !this.props.inventoryId || !this.props.inventoryId.id) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.selectedOrg.shortId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories`,
                    breadcrumbName: `${this.props.selectedOrg.name}'s Portfolio`,
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories/${ this.props.inventoryId.id }`,
                    breadcrumbName: `Inventory ${ this.props.inventoryId.name }`,
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories/${ this.props.inventoryId.id }/property-taxes/${ this.props.match.params.propertyTaxId }`,
                    breadcrumbName: this.props.match.params.propertyTaxId === 'new' ? 'New Property Tax' : 'Edit Property Tax',
                },
            ],
        };
    }

    get headerContent() {
        return (
            <Typography.Paragraph>
                When creating property tax due, we attempt to calculate the amount per acre to then determine how much each tract owes.
                Unless you intentionally state a tract is not eligible for property tax reimbursement, it will be included in the calculation (<a href={`${ process.env.REACT_APP_DOCS_URL }/app/guides/property-taxes`} target="_blank" rel="noopener noreferrer">see here for information</a>).
                Unselecting a tract means that tract will not be responsible for paying the taxes.
                If you need to prorate any tract, click the edit button and enter a custom amount in.
            </Typography.Paragraph>
        );
    }
    //#endregion header

    get loadedLayout() {
        if (!this.props.inventoryId) {
            return null;
        }

        const headerProps: PageContainerProps = {
            title: `New Property Tax for: ${ this.props.inventoryId.name }`,
            subTitle: <PropertyTaxStatusTag status={this.state.editingTaxRecord ? this.state.editingTaxRecord.status : PropertyTaxStatus.New} />,
            content: this.headerContent,
            onBack: this.onBack,
            breadcrumb: this.breadcrumbProps,
            extra: this.extra,
            className: isMobileOnly ? 'mobile' : '',
        };

        const customizedMap: { [key: string]: number } = {};
        this.state.tracts.forEach((t) => {
            if (t.customized) {
                customizedMap[t.id] = currency(t.taxCost, { precision: 2 }).value;
            }
        });

        return (
            <PageContainer {...headerProps}>
                <Card bordered={false}>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        onValuesChange={this.valuesChanged}
                        onFinish={this.onFormSubmit}
                        initialValues={{
                            year: this.state.editingTaxRecord ? dayjs(`${ this.state.editingTaxRecord.taxYear }`) : dayjs(),
                            totalOwed: this.state.editingTaxRecord ? this.state.editingTaxRecord.totalOwed : '0.00',
                            perAcre: this.state.editingTaxRecord ? this.state.editingTaxRecord.perAcre : '0.00',
                            dueDate: this.state.editingTaxRecord ? dayjs(this.state.editingTaxRecord.dueDate) : undefined,
                            customized: customizedMap,
                        }}
                    >
                        <Row>
                            <Col span={11}>{this.taxYearInput}</Col>
                            <Col offset={1} span={12}>{this.totalOwedInput}</Col>
                        </Row>

                        <Row>
                            <Col span={11}>{this.taxPerAcreInput}</Col>
                            <Col offset={1} span={12}>{this.dueDateInput}</Col>
                        </Row>

                        <Row>
                            <Col span={24}>{this.tractsTable}</Col>
                        </Row>
                    </Form>
                </Card>

                { this.state.editingTaxRecord ?
                    <FinalizePropertyTax
                        visible={this.state.finalizing}
                        tax={this.state.editingTaxRecord}
                        close={this.onFinalizeCancel}
                    />
                : null }
            </PageContainer>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        if (this.state.notFound) {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="We searched but failed to find that Property Tax. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Back to the Inventory</Button>}
                />
            );
        }

        return this.loadedLayout;
    }
}

export const PropertyTax = withRouter(connect(mapStateToProps)(PropertyTaxBase));
