import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { App, Button, Drawer, Form, Radio, Space } from 'antd';

import type { ILateFeeConfig, ILateFeeTier } from 'models';
import type { ILoan } from 'models/loan';
import type { IRental } from 'models/rental';

import { LateFeeTierTag, LateFeeTierModal } from 'components/lateFees';
import { useResetFormOnCloseModal } from 'hooks/resetFormOnCloseModal';
import { displayErrorNotification } from 'utils/errors';

import { updateLateFeeConfig } from 'api/loans';

interface IFormValues {
    lateFeeDisabled: boolean;
    tiers: ILateFeeTier[];
}

interface IChangeLateFeeConfigDrawerProps {
    type: 'loan' | 'rental';
    visible: boolean;
    loan?: ILoan;
    rental?: IRental;
    close: (saved?: boolean) => void;
}

export const ChangeLateFeeConfigDrawer: React.FC<IChangeLateFeeConfigDrawerProps> = ({ visible, type, loan, close }) => {
    const { notification } = App.useApp();
    const [saving, setSaving] = useState(false);
    const [addTierOpen, setAddTierOpen] = useState(false);
    const [form] = Form.useForm<IFormValues>();

    useResetFormOnCloseModal({ form, open: visible });

    useEffect(() => {
        if (!loan) {
            return;
        }

        form.setFieldsValue({
            lateFeeDisabled: loan.lateFeeConfig.disabled,
            tiers: loan.lateFeeConfig.tiers,
        });
    }, [form, loan]);

    const removeTier = useCallback((tier: ILateFeeTier) => {
        const tiers: ILateFeeTier[] = form.getFieldValue('tiers') || [];
        form.setFieldsValue({ tiers: tiers.filter((t) => t !== tier) });
    }, [form]);

    const addTier = useCallback((tier?: ILateFeeTier) => {
        if (!tier) {
            setAddTierOpen(false);
            return;
        }

        const tiers = form.getFieldValue('tiers') || [];
        form.setFieldsValue({ tiers: [...tiers, tier] });

        setAddTierOpen(false);
    }, [form]);

    const onCancelClick = useCallback(() => close(false), [close]);

    const onSaveClick = () => {
        setSaving(true);

        form.validateFields().then(async (values) => {
            if (values.tiers.length === 0) {
                notification.error({ message: 'At least one late fee tier is required.' });
                return;
            }

            if (type === 'rental' && values.tiers.length !== 1) {
                notification.error({ message: 'Rentals are only allowed one late fee tier.' });
                return;
            }

            const lateFeeConfig: ILateFeeConfig = {
                disabled: values.lateFeeDisabled,
                tiers: values.tiers,
            };

            try {
                switch (type) {
                    case 'loan':
                        await updateLateFeeConfig(loan!.organization.id, loan!.id, lateFeeConfig);
                        break;
                    case 'rental':
                        throw new Error('saving not implemented');
                }

                notification.success({ message: 'Late Fee config successfully updated.' });
                close(true);
            } catch (e) {
                displayErrorNotification(e);
            }

        }).catch((e) => {
            console.log('failed to validate the form:', e);
        }).finally(() => setSaving(false));
    };

    let width: string = '500px';
    if (isMobileOnly) {
        width = '100vw';
    }

    return (
        <Drawer
            title="Change Late Fee Config"
            open={visible}
            maskClosable={!saving}
            closable={!saving}
            width={width}
            extra={
                <Space>
                    <Button onClick={onCancelClick} style={{ marginRight: 8 }} disabled={saving}>Cancel</Button>
                    <Button onClick={onSaveClick} type="primary" disabled={saving} loading={saving}>Save</Button>
                </Space>
            }
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                disabled={saving}
                initialValues={{
                    lateFeeDisabled: loan?.lateFeeConfig.disabled,
                    tiers: loan?.lateFeeConfig.tiers,
                }}
            >
                <Form.Item
                    name="lateFeeDisabled"
                    label="Late Fees Applied"
                    extra="Do you want Lendiom to automatically apply late fees?"
                    rules={[{ required: true, message: 'Please indicate whether or not to automatically apply late fees.' }]}
                >
                    <Radio.Group buttonStyle="solid" disabled={saving}>
                        <Radio.Button value={false}>Automatically</Radio.Button>
                        <Radio.Button value={true}>Manually</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="tiers" noStyle hidden />

                <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.tiers !== curr.tiers}>
                    {({ getFieldValue }) => {
                        const tiers: ILateFeeTier[] = getFieldValue('tiers') || [];

                        const addDisabled = (type === 'rental' && tiers.length === 1) || saving;

                        return (
                            <Form.Item
                                label="Late Fee Tiers"
                                required
                                extra="What are the formulas for late fees? More than one late fee can be applied, which is why there are tiers."
                            >
                                <Space.Compact style={{ flexWrap: 'wrap', alignItems: 'center', flexFlow: 'wrap' }}>
                                    {
                                        tiers.length > 0
                                        ? tiers.map((t, i) => <span key={`tier-${ i }`} style={{ marginTop: '2px', marginBottom: '2px' }}><LateFeeTierTag value={t} closable onClose={removeTier} /></span>)
                                        : null
                                    }

                                    <Button size="small" onClick={() => setAddTierOpen(true)} disabled={addDisabled}>Add tier</Button>
                                </Space.Compact>
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            </Form>

            <LateFeeTierModal open={addTierOpen} type={type} onAdd={addTier} />
        </Drawer>
    );
}
