import React, { lazy, Suspense } from 'react';
import currency from 'currency.js';
import type { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Row, Col, FormInstance, Button, Collapse, Spin, Descriptions } from 'antd';

import { IClientAutoCompleteValue } from 'components/clients/autocomplete';
import { LateFeeTierTag } from 'components/lateFees';
import { LongCurrency, SimpleDate } from 'utils/formatting';

import type { IInventory } from 'models/inventory';
import type { ITract } from 'models/tract';
import { PaymentFrequency } from 'models';
import { ILoan, LoanTermsInterestAccruesDailyFirstPaymentBasis, LoanTermsInterestSchedule, LoanType } from 'models/loan';
import type { ILoanSchedule } from 'models/loanSchedule';
import { EscrowApplicationStep } from 'models/escrow';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { sanitizeCurrency } from 'utils/numberFormatting';
import { displayErrorNotification } from 'utils/errors';

import { generateAmortizationSchedule } from 'api/helpers';

import { INewLoanFormValues } from './formValues';

const LoanScheduleTable = lazy(() => import('components/loans/scheduleTable'));
const LoanDownPaymentCollectionModal = lazy(() => import('components/loans/downPaymentCollectionModal'));

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    orgId: getSelectedOrgShortId(state),
});

interface INewLoanReviewStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    resInv?: IInventory;
    landInv?: IInventory;
    tracts?: ITract[];
    goNext: () => void;
    goBack: () => void;
    isSaving: boolean;
}

interface INewLoanFormReviewStepState {
    inventory?: IInventory;
    loan: Partial<ILoan>;
    schedule?: ILoanSchedule;
    loadingError: boolean;
    downPaymentModalOpen: boolean;
}

class NewLoanFormReviewStepBase extends React.PureComponent<INewLoanReviewStepProps, INewLoanFormReviewStepState> {
    state: Readonly<INewLoanFormReviewStepState> = {
        loan: {},
        loadingError: false,
        downPaymentModalOpen: false,
    }

    async componentDidMount() {
        const loanType = this.props.form.getFieldValue('type');

        switch (loanType) {
            case LoanType.Tract:
                this.setState({ inventory: this.props.landInv });
                break;
            case LoanType.Residential:
                this.setState({ inventory: this.props.resInv });
                break;
        }

        const loan: Partial<ILoan> = {
            type: loanType,
            status: this.props.form.getFieldValue('status'),
            terms: {
                downPayment: this.props.form.getFieldValue('downPayment'),
                salesPrice: this.props.form.getFieldValue('salesPrice'),
                length: this.props.form.getFieldValue('length'),
                lengthUnit: this.props.form.getFieldValue('lengthUnit'),
                rate: this.props.form.getFieldValue('interestRate'),
                interestSchedule: this.props.form.getFieldValue('interestSchedule'),
                interestFormula: this.props.form.getFieldValue('interestFormula'),
                firstPaymentBasis: this.props.form.getFieldValue('firstPaymentBasis'),
                paymentFrequency: PaymentFrequency.Monthly,
                payment: sanitizeCurrency(this.props.form.getFieldValue('paymentAmount')) || '0',
                overwritePayment: this.props.form.getFieldValue('overwritePaymentAmount'),
                extraApplication: this.props.form.getFieldValue('extraApplication'),
                wasExisting: this.props.form.getFieldValue('isExistingLoan') || false,
                existing: {
                    principalPaid: this.props.form.getFieldValue('principalPaid') || '',
                    interestPaid: this.props.form.getFieldValue('interestPaid') || '',
                    interestPaidYtd: this.props.form.getFieldValue('interestPaidYtd') || '',
                    unpaidInterest: this.props.form.getFieldValue('interestBalanceUnpaid') || '',
                    lastPaidInFullPayment: this.props.form.getFieldValue('lastPaidInFullPayment') || 0,
                    lastPaymentDate: this.props.form.getFieldValue('lastPaymentDate') ? this.props.form.getFieldValue('lastPaymentDate').toJSON() : '',
                    nextPaymentDate: this.props.form.getFieldValue('nextPaymentDate') ? this.props.form.getFieldValue('nextPaymentDate').toJSON() : '',
                },
            },
            defaulting: {
                automatic: this.props.form.getFieldValue('defaultingAutomaticallyEnabled'),
                days: this.props.form.getFieldValue('daysUntilInDefault'),
                defaultsAfter: this.props.form.getFieldValue('daysUntilDefaulted'),
            },
            firstPaymentDate: this.props.form.getFieldValue('firstPaymentDate').toJSON(),
            closingDate: this.props.form.getFieldValue('closingDate').toJSON(),
            downPaymentDate: this.props.form.getFieldValue('downPaymentDate')?.toJSON() || '',
            lateFeeConfig: {
                disabled: this.props.form.getFieldValue('lateFeeDisabled') || false,
                tiers: this.props.form.getFieldValue('lateFeeTiers') || [],
            },
            communication: {
                automated: this.props.form.getFieldValue('automatedCommunication'),
                preferences: this.props.form.getFieldValue('communicationPreferences'),
            },
            onlinePaymentConfig: {
                enabled: this.props.form.getFieldValue('onlinePaymentsEnabled'),
                statementDescriptor: this.props.form.getFieldValue('onlineStatementDescriptor'),
                platformFeePayee: this.props.form.getFieldValue('onlinePaymentPlatformFeePayee'),
                allowPrincipalOnly: this.props.form.getFieldValue('allowPrincipalOnly'),
                allowAutoDraft: this.props.form.getFieldValue('allowAutoDraft'),
            },
            downPayment: this.props.form.getFieldValue('downPaymentConfig'),
        };

        if (this.props.form.getFieldValue('collectEscrow')) {
            loan.escrow = {
                balance: this.props.form.getFieldValue('escrowStartingBalance') || '',
                paymentAmount: this.props.form.getFieldValue('escrowPaymentAmount') || '',
                applicationStep: this.props.form.getFieldValue('escrowApplicationStep' || EscrowApplicationStep.BeforeInterest),
            };
        }

        if (loanType === LoanType.Residential) {
            loan.terms!.adjustments = this.props.form.getFieldValue('totalAdjustments') || '0';
        }

        await this.loadSchedule(loan);

        this.setState({ loan });
    }

    async loadSchedule(loan?: Partial<ILoan>) {
        if (!loan) {
            return;
        }

        return new Promise<void>((resolve) => {
            this.setState({ schedule: undefined, loadingError: false }, async () => {
                try {
                    const schedule = await generateAmortizationSchedule(
                        loan.terms!,
                        loan.firstPaymentDate!,
                        loan.escrow?.paymentAmount || undefined,
                        { closingDate: loan.closingDate, downPaymentDate: loan.downPaymentDate },
                    );

                    this.setState({ schedule });
                } catch (e) {
                    console.warn('failed to get the amortization schedule:', e);
                    displayErrorNotification(e);
                    this.setState({ loadingError: true });
                }

                resolve();
            });
        });
    }

    refreshSchedule = () => {
        this.loadSchedule(this.state.loan);
    }

    get tractDescriptionItem() {
        if (!this.state.inventory || !this.props.tracts || this.props.tracts.length === 0) {
            return null;
        }

        const tractLinks = this.props.tracts.map((t, i, tracts) => (
            <span key={t.id}>
                <Link to={`/${this.props.orgId}/inventories/${t.inventoryId}/tracts/${t.id}`} target="_blank" title={`${ t.label }. Opens in a new tab`}>{t.number}</Link>
                { i+1 === tracts.length ? '' : ',' }
                &nbsp;
            </span>
        ));

        return (
            <Descriptions.Item label={this.props.tracts.length === 1 ? 'Tract' : 'Tracts'}>Tracts&nbsp;{tractLinks}</Descriptions.Item>
        );
    }

    get details() {
        const { loan, schedule } = this.state;
        if (!loan || !loan.terms || !loan.lateFeeConfig || !loan.communication) {
            return (
                <Spin spinning />
            );
        }

        const isAccruesDaily = loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily;
        const salesPrice = currency(loan.terms.salesPrice, { precision: 2 });
        const downPayment = currency(loan.terms.downPayment, { precision: 2 });
        const adjustments = currency(loan.terms.adjustments || 0, { precision: 2 });
        const interestRate = currency(loan.terms.rate, { precision: 2 });
        const length = currency(loan.terms.length, { precision: 0 });
        const lengthUnit = loan.terms.lengthUnit;

        let paymentAmount = this.props.form.getFieldValue('paymentAmount');
        if (schedule && schedule.payments && schedule.payments.length > 0 && !isAccruesDaily) {
            paymentAmount = schedule.payments[0].payment;
        }

        let downPaymentDate: Dayjs | undefined = undefined;
        if (isAccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate) {
            downPaymentDate = this.props.form.getFieldValue('downPaymentDate') as Dayjs;
        }

        const closingDate = this.props.form.getFieldValue('closingDate') as Dayjs;
        const firstPaymentDate = this.props.form.getFieldValue('firstPaymentDate') as Dayjs;
        const lastPaymentDate = this.props.form.getFieldValue('lastPaymentDate') as Dayjs;
        const nextPaymentDate = this.props.form.getFieldValue('nextPaymentDate') as Dayjs;
        const client = this.props.form.getFieldValue('client') as IClientAutoCompleteValue;
        const label = this.props.form.getFieldValue('label') as string;

        return (
            <Descriptions>
                <Descriptions.Item label="Label" span={2}>{label}</Descriptions.Item>
                <Descriptions.Item label="Type" className="title-caps" span={1}>{loan.type ? loan.type.replaceAll('-', ' ') : '-'}</Descriptions.Item>
                <Descriptions.Item label="Status" className="title-caps">{loan.status ? loan.status : '-'}</Descriptions.Item>
                {this.state.inventory ? <Descriptions.Item label="Inventory"><Link to={`/${this.props.orgId}/inventories/${this.state.inventory.id}`} target="_blank" title="Opens in a new tab">{this.state.inventory.name}</Link></Descriptions.Item> : null}
                {this.state.inventory && Array.isArray(this.props.tracts) ? this.tractDescriptionItem : null}
                <Descriptions.Item label="Client"><Link to={`/${this.props.orgId}/clients/${client.id}`} target="_blank" title="Opens in a new tab">{client.displayName}</Link></Descriptions.Item>
                <Descriptions.Item label="Communication" className="title-caps">{loan.communication.preferences.join(', ')} ({loan.communication.automated ? 'automatic' : 'manual'})</Descriptions.Item>
                <Descriptions.Item label="Sales Price">{salesPrice.format(true)}</Descriptions.Item>
                <Descriptions.Item label="Down Payment">{downPayment.format(true)}</Descriptions.Item>
                { loan.downPayment ? <Descriptions.Item label="Online Down Payment"><Button size="small" onClick={() => this.setState({ downPaymentModalOpen: true })}>View Details</Button></Descriptions.Item> : null }
                { loan.terms.adjustments ? <Descriptions.Item label="Total Adjustments"><LongCurrency value={loan.terms.adjustments} /></Descriptions.Item> : null }
                <Descriptions.Item label="Loan Amount">{ currency(salesPrice).subtract(downPayment).add(adjustments).format(true) }</Descriptions.Item>
                <Descriptions.Item label="Payment Frequency">Monthly</Descriptions.Item>
                <Descriptions.Item label="Payment Terms">{length.format()} {lengthUnit}</Descriptions.Item>
                <Descriptions.Item label="Payment Amount"><LongCurrency value={paymentAmount} /></Descriptions.Item>
                <Descriptions.Item label="Extra Application" className="title-caps">{loan.terms.extraApplication ? loan.terms.extraApplication.replaceAll('-', ' ') : '-'}</Descriptions.Item>
                <Descriptions.Item label="Interest Rate">{interestRate.format()}%</Descriptions.Item>
                <Descriptions.Item label="Interest Schedule" className="title-caps">{loan.terms.interestSchedule.replaceAll('-', ' ')}</Descriptions.Item>
                { isAccruesDaily ? <Descriptions.Item label="Interest Formula" className="title-caps">{ loan.terms.interestFormula ? loan.terms.interestFormula.replaceAll('/', ' / ') : '-' }</Descriptions.Item> : null}
                { isAccruesDaily ? <Descriptions.Item label="Finance Start" className="title-caps">{ loan.terms.firstPaymentBasis ? loan.terms.firstPaymentBasis.replaceAll('-', ' ') : '-' }</Descriptions.Item> : null }
                { isAccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate ? <Descriptions.Item label="Down Payment Date"><SimpleDate date={downPaymentDate?.toISOString()} /></Descriptions.Item> : null }
                { !isAccruesDaily ? <Descriptions.Item label="Total of Payments">{schedule ? currency(schedule.totalPayments).format(true) : '-'}</Descriptions.Item> : null}
                { !isAccruesDaily ? <Descriptions.Item label="Total Interest">{schedule ? currency(schedule.totalInterest).format(true) : '-'}</Descriptions.Item> : null}
                <Descriptions.Item label="Days Until In-Default">{ loan.defaulting?.days }</Descriptions.Item>
                <Descriptions.Item label="Automatic Defaulting Enabled">{ loan.defaulting?.automatic ? 'Yes' : 'No' }</Descriptions.Item>
                { loan.defaulting?.automatic ? <Descriptions.Item label="Defaults After">{ loan.defaulting?.defaultsAfter } Days</Descriptions.Item> : null }
                <Descriptions.Item label="Closing Date"><SimpleDate date={closingDate.toISOString()} /></Descriptions.Item>
                <Descriptions.Item label="First Payment Date"><SimpleDate date={firstPaymentDate.toISOString()} /></Descriptions.Item>
                <Descriptions.Item label="Late Fees Applied">{ loan.lateFeeConfig?.disabled ? 'Manually' : 'Automatically' }</Descriptions.Item>
                <Descriptions.Item label="Late Fee Tiers">{ loan.lateFeeConfig?.tiers.map((t, i) => <React.Fragment key={`late-fee-tier-${ i }`}><LateFeeTierTag value={t} /></React.Fragment>) }</Descriptions.Item>
                <Descriptions.Item label="Online Payments">{loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? 'Enabled' : 'Disabled'}</Descriptions.Item>
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Statement Descriptor">{loan.onlinePaymentConfig.statementDescriptor.toUpperCase()}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Platform Fee Payee" className="title-caps">{loan.onlinePaymentConfig.platformFeePayee.replaceAll('-', ' ')}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Allow Principal Only" className="title-caps">{loan.onlinePaymentConfig.allowPrincipalOnly ? 'Yes' : 'No'}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Allow Auto Draft" className="title-caps">{loan.onlinePaymentConfig.allowAutoDraft ? 'Yes' : 'No'}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Existing Loan">Yes</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Principal Paid">{currency(loan.terms.existing.principalPaid).format(true)}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Interest Paid">{currency(loan.terms.existing.interestPaid).format(true)}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Interest Paid YTD"><LongCurrency value={loan.terms.existing.interestPaidYtd} /></Descriptions.Item> : null}
                {loan.terms.wasExisting && isAccruesDaily ? <Descriptions.Item label="Unpaid Interest"><LongCurrency value={loan.terms.existing.unpaidInterest} /></Descriptions.Item> : null}
                {loan.terms.wasExisting && !isAccruesDaily ? <Descriptions.Item label="Last Paid In Full Payment">{loan.terms.existing.lastPaidInFullPayment}</Descriptions.Item> : null}
                {loan.terms.wasExisting && isAccruesDaily ? <Descriptions.Item label="Last Payment Date"><SimpleDate date={lastPaymentDate.toISOString()} /></Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Next Payment Date"><SimpleDate date={nextPaymentDate.toISOString()} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Starting Balance"><LongCurrency value={loan.escrow.balance} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Payment Amount"><LongCurrency value={loan.escrow.paymentAmount} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Application Step" className="title-caps">{loan.escrow.applicationStep.replace('-', ' ')}</Descriptions.Item> : null}
            </Descriptions>
        );
    }

    get amortizationSchedule() {
        return (
            <Spin spinning={typeof this.state.schedule === 'undefined'} tip={this.state.loadingError ? <Button size="small" onClick={this.refreshSchedule}>Retry</Button> : null}>
                <Collapse defaultActiveKey={[]} collapsible={this.state.loadingError ? 'disabled' : 'header'}>
                    <Collapse.Panel key="collapse-schedule" header="Amortization Schedule">
                        <Suspense fallback={null}>
                            <LoanScheduleTable interestSchedule={this.state.loan.terms?.interestSchedule || LoanTermsInterestSchedule.FollowsPayments} schedule={this.state.schedule!} showDate showEscrow={typeof this.state.loan?.escrow !== 'undefined'} />
                        </Suspense>
                    </Collapse.Panel>
                </Collapse>
            </Spin>
        );
    }

    get bottomButtons() {
        return (
            <Row style={{ marginTop: '10px' }}>
                <Col>
                    <Button.Group>
                        <Button icon={<LeftOutlined />} onClick={this.props.goBack} disabled={this.props.isSaving}>Previous</Button>
                        <Button type="primary" onClick={this.props.goNext} disabled={this.props.isSaving || this.state.loadingError} loading={this.props.isSaving}>
                            Save <SaveOutlined />
                        </Button>
                    </Button.Group>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.details}
                {this.amortizationSchedule}

                {this.bottomButtons}

                <Suspense fallback={null}>
                    <LoanDownPaymentCollectionModal
                        open={this.state.downPaymentModalOpen}
                        readOnly
                        downPaymentAmount={currency(this.state.loan?.terms?.downPayment || '0', { precision: 2 })}
                        initial={this.state.loan?.downPayment}
                        close={() => this.setState({ downPaymentModalOpen: false })}
                    />
                </Suspense>
            </React.Fragment>
        );
    }
}

export const NewLoanReviewStep = connect(mapStateToProps)(NewLoanFormReviewStepBase);
