import { ILookup } from './common/lookup';
import { IDescription } from './common/description';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { IPostalAddress } from './common/postalAddress';
import { Currency } from './currency';

export interface IInventory extends IObjectDates, IOrganizational {
    id: string;
    name: string;
    category: InventoryCategory;
    type: InventoryType;
    status: InventoryStatus;
    address: IPostalAddress;

    pinned: boolean;

    landDetails?: IInventoryLandDetails;
    residentialDetails?: IResidentialDetails;
}

export enum InventoryCategory {
    Land = 'land',
    Residential = 'residential',
    Commercial = 'commercial',
    Other = 'other',
}

export enum InventoryType {
    Finance = 'finance',
    Rental = 'rental',
    Other = 'other',
}

export enum InventoryStatus {
    Idea = 'idea',
    Ready = 'ready',
    InProgress = 'in-progress',
    Completed = 'completed',
    //shared between non-land financed and rentals
    Late = 'late',
    Eviction = 'eviction',
    Evicted = 'evicted',
    //rental
    Available = 'available',
    Rented = 'rented',
}

export interface IInventoryLandDetails {
    parcelNumbers: string[];

    totalAcres: number;
    costTotal: string;
    costPerAcre: string;
}

export interface IResidentialDetails extends IDescription {
    parcelNumbers: string[];

    bedrooms: number;
    bathrooms: number;
    livingSquareFootage: number;
    lotArea: number;
    lotAreaUnit: ResidentialDetailsLotAreaUnit;

    price: Currency;
    rent?: Currency;

    owner?: ILookup;
    loan?: ILookup;
    rental?: ILookup;
}

export enum ResidentialDetailsLotAreaUnit {
    Acres = 'acres',
    SquareFeet = 'sqft',
}
