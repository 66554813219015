import React from 'react';
import type { FC } from 'react';
import { Form, Select, Switch } from 'antd';

import { CommunicationPreference } from 'models';

interface ILateFeeInputsProps {
    allowMultiple?: boolean;
}

export const CommunicationInputs: FC<ILateFeeInputsProps> = ({ allowMultiple }) => {
    return (
        <React.Fragment>
            <Form.Item
                name="automatedCommunication"
                label="Automated Communication"
                extra="Should the communication to the client be automated or manual?"
                valuePropName="checked"
                rules={[{ required: true, message: 'Do you want automated communications enabled?' }]}
            >
                <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
            </Form.Item>

            <Form.Item
                name="communicationPreference"
                label="Communication Preference"
                extra="How should automated information be sent to the Client?"
                rules={[{ required: true, message: 'Please select which route to communicate to the client.' }]}
            >
                <Select<CommunicationPreference> mode={allowMultiple ? 'multiple' : undefined}>
                    <Select.Option key={CommunicationPreference.SMS} value={CommunicationPreference.SMS}>SMS (text messages)</Select.Option>
                    <Select.Option key={CommunicationPreference.Email} value={CommunicationPreference.Email} disabled>Email</Select.Option>
                    <Select.Option key={CommunicationPreference.Mail} value={CommunicationPreference.Mail} disabled>Snail Mail</Select.Option>
                </Select>
            </Form.Item>
        </React.Fragment>
    );
}
