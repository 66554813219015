import { get, post, del, put, postForm } from './';
import type { IRestRequest } from 'models/common/restRequest';
import type { IRestResult } from 'models/common/restResult';
import type { FileMap, IFile } from 'models/file';
import type { INote } from 'models/notes';
import type { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import type { IRentalTransactionStatusChangePayload, IRentalUpdateOnlinePaymentConfigPayload } from 'models/payloads/rentals';
import type { IRental, RentalStatus } from 'models/rental';
import type { IRentalTransaction } from 'models/rentalTransaction';

export const getRentals = (orgId: string, req?: IRestRequest) => get<IRestResult<IRental>>(`/organizations/${ orgId }/rentals`, true, req);
export const createRental = (orgId: string, rental: Partial<IRental>) => post<Partial<IRental>, IRental>(`/organizations/${ orgId }/rentals`, rental, true);
export const getRentalByID = (orgId: string, rentalId: string) => get<IRental>(`/organizations/${ orgId }/rentals/${ rentalId }`, true);
export const deleteRentalByID = (orgId: string, rentalId: string) => del(`/organizations/${ orgId }/rentals/${ rentalId }`, true);
export const setRentalStatus = (orgId: string, rentalId: string, status: RentalStatus) => put<{ status: RentalStatus }, IRental>(`/organizations/${ orgId }/rentals/${ rentalId }/status`, { status }, true);
export const updateRentalOnlinePaymentConfig = (orgId: string, rentalId: string, payload: IRentalUpdateOnlinePaymentConfigPayload) => post<IRentalUpdateOnlinePaymentConfigPayload, IRental>(`/organizations/${ orgId }/rentals/${ rentalId }/online-config`, payload, true);
// export const updateLateFeeConfig = (orgId: string, rentalId: string, payload: ILateFeeConfig) => post<ILateFeeConfig, void>(`/organizations/${ orgId }/loans/${ loanId }/late-fee-config`, payload, true);

export const createRentalTransaction = (orgId: string, rentalId: string, trans: Partial<IRentalTransaction>) => post<Partial<IRentalTransaction>, IRentalTransaction>(`/organizations/${ orgId }/rentals/${ rentalId }/transactions`, trans, true);
export const getRentalTransactions = (orgId: string, rentalId: string, req?: IRestRequest) => get<IRestResult<IRentalTransaction>>(`/organizations/${ orgId }/rentals/${ rentalId }/transactions`, true, req);
export const setRentalTransactionStatus = (orgId: string, rentalId: string, transactionId: string, payload: Partial<IRentalTransactionStatusChangePayload>) => post<Partial<IRentalTransactionStatusChangePayload>, void>(`/organizations/${ orgId }/rentals/${ rentalId }/transactions/${ transactionId }/status`, payload, true);

export const getRentalNotes = (orgId: string, rentalId: string) => get<INote[]>(`/organizations/${ orgId }/rentals/${ rentalId }/notes`, true);
export const addRentalNote = (orgId: string, rentalId: string, note: Partial<INote>) => post<Partial<INote>, INote>(`/organizations/${ orgId }/rentals/${ rentalId }/notes`, note, true);
export const updateRentalNote = (orgId: string, rentalId: string, note: Partial<INote>) => put<Partial<INote>, INote>(`/organizations/${ orgId }/rentals/${ rentalId }/notes/${ note.id }`, note, true);
export const deleteRentalNote = (orgId: string, rentalId: string, noteId: string) => del(`/organizations/${ orgId }/rentals/${ rentalId }/notes/${ noteId }`, true);

export const getRentalFilesAsVFS = (orgId: string, rentalId: string) => get<FileMap>(`/organizations/${ orgId }/rentals/${ rentalId }/files?as=vfs`, true);
export const getRentalFile = (orgId: string, rentalId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/rentals/${ rentalId }/files/${ fileId }`, true);
export const uploadRentalFile = (orgId: string, rentalId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/rentals/${ rentalId }/files`, formData, true);
export const updateRentalFile = (orgId: string, rentalId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/rentals/${ rentalId }/files/${ fileId }`, payload, true);
export const createNewRentalFileFolder = (orgId: string, rentalId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/rentals/${ rentalId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const deleteRentalFile = (orgId: string, rentalId: string, fileId: string) => del(`/organizations/${ orgId }/rentals/${ rentalId }/files/${ fileId }`, true);
